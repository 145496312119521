import { faPlusCircle, faTimesCircle, faHome, faArrowCircleLeft, faArrowCircleRight, faSmile, faFrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import glamorous, { Div } from 'glamorous';
import { Field, Form, Formik, isObject } from 'formik';
import React from 'react';
import { get } from 'lodash';
import { translate, WithNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import {Button, Col, Row } from 'reactstrap';
import constants from '../../../../utils/constants';
import { IStepProps } from '../../interfaces/IStepProps';
import UniCheckbox from '../UniFields/UniCheckbox';
import UniField from '../UniFields/UniField';
import UniRadio from '../UniFields/UniRadio';
import { WizardService } from "../../services/WizardService";
import HealthInsuranceOffice from "./FormFields/HealthInsuranceOffice";
import VzpBranch from "./FormFields/VzpBranch";
import HelpIcon from '../../../../components/HelpIcon/HelpIcon';
import NumberOfMonths from "../UniFields/NumberOfMonths";
import CatalogComboField from "../../../../components/CatalogComboField/CatalogComboField";
import CountriesComboField from "../../../../components/CatalogComboField/CountriesComboField";
import NumberFormat from 'react-number-format';
import theme from "../../../../styles/theme";
import ValidationWrapper from "../../../../components/ValidationWrapper/ValidationWrapper";
import HelpLink from "../../../../components/HelpLink/HelpLink";
import MyDatePicker from '../../../../components/DatePicker/DatePicker';
import AccountForm from "./components/AccountForm";
import UniDisabledCheckbox from "../UniFields/UniDisabledCheckbox";

interface IStepValidation {
    valid: boolean;
    loading: boolean;
    loaded: boolean;
}

interface IStepFourState {
    1: IStepValidation;
    2: IStepValidation;
    3: IStepValidation;
}

class StepFourForm extends React.Component<WithNamespaces & IStepProps, IStepFourState> {

    readonly state = {
        1: {
            valid: false,
            loading: false,
            loaded: false,
        },
        2: {
            valid: false,
            loading: false,
            loaded: false,
        },
        3: {
            valid: false,
            loading: false,
            loaded: false,
        },
    };

    private validateStep(step: number) {
        if (this.state[step].loading || this.state[step].loaded) {
            return;
        }

        const stateWithLoading = {...this.state};
        stateWithLoading[step].loading = true;
        this.setState(stateWithLoading);

        this.props.validate(this.props.taxReturn.id, step)
            .then((values) => {
                const newState = {...this.state};
                newState[step] = {
                    valid: true,
                    loading: false,
                    loaded: true,
                };
                this.setState(newState);
            })
            .catch((errors) => {
                const newState = {...this.state};
                newState[step] = {
                    valid: false,
                    loading: false,
                    loaded: true,
                };
                this.setState(newState);
            });
    }

    isLoaded = () => {
        return this.state[1].loaded && this.state[2].loaded || this.state[3].loaded;
    };

    hasErrors = () => {
        return (this.state[1].loaded && !this.state[1].valid)
            || (this.state[2].loaded && !this.state[2].valid)
            || (this.state[3].loaded && !this.state[3].valid);
    };

    componentDidUpdate(): void {
        const { taxReturn } = this.props;

        if (taxReturn.id) {
            this.validateStep(1);
            this.validateStep(2);
            this.validateStep(3);
        }
    }

	render() {
        const { t, taxReturn, lang, update, validate, shouldValidate } = this.props;
        const thousandSeparator = lang === 'cs' ? ' ' : ',';
        const czkSuffix = lang === 'cs' ? ' Kč' : ' CZK';

        if (!taxReturn.id) {
            return null;
        }

        const wizardService = new WizardService(4, taxReturn.id, update, validate, t);

        const months = (new Array(12).fill(1)).map((item, index) => index);
        const infoTypes = [
            'employed', 'parental', 'maternity', 'ill',
            'retired', 'retiredNoPayments', 'disabled', 'student', 'disabledCare'
        ];
        let monthsInsuredMax = 0;
        if (taxReturn.socialInsurance.selfEmployedWholeYear) {
            monthsInsuredMax = 12;
        } else if (Array.isArray(taxReturn.socialInsurance.selfEmployedMonths)) {
            taxReturn.socialInsurance.selfEmployedMonths.map(month => {
                if (month) {
                    ++monthsInsuredMax;
                }
            });
        }
        else {
            monthsInsuredMax = Object.keys(taxReturn.socialInsurance.selfEmployedMonths).length;
        }

        return (
            <Formik
                initialValues={taxReturn}
                onSubmit={values => console.log(values)}
                validateOnChange={false}
                validate={wizardService.validateAndSave}
            >
              {formik => {
                const {values, validateForm, setFieldTouched, setFieldValue} = formik;

                const datePickerProps = (path: string, required = false) => ({
                  ...formik,
                  name: path,
                  value: get(values, path),
                  lang,
                  required,
                  year: taxReturn.year,
                });

                return (<Div>
                  <Div className="navButtons">
                    <Button title={t('buttons.backToDashboard.label')} onClick={() => wizardService.backToDashboard()}>
                        <FontAwesomeIcon icon={faHome} />
                    </Button>
                    <Button title={t('buttons.previous.label')} onClick={() => wizardService.previousStep()}>
                        <FontAwesomeIcon icon={faArrowCircleLeft} />
                    </Button>
                    <Button title={t('buttons.next.label')} className="btn-primary" onClick={() => wizardService.nextStep(validateForm, setFieldTouched, t)}>
                        <FontAwesomeIcon icon={faArrowCircleRight} />
                    </Button>
                </Div>
                <h1>{taxReturn.personalData.firstName} {taxReturn.personalData.lastName} {taxReturn.year}</h1>
                <p>{t('wizard.subtitle.label')}</p>
                {this.hasErrors() && <Div className="formSection">
                    <h3>{t('errorsHeadline.label')}</h3>
                    <p>{t('errorsHeadline.placeholder')}</p>
                    <Div className="subForm">
                        {!this.state[1].valid && <Div>
                            <Link to={constants.ROUTES.WIZARD.STEP[1] + '/' + taxReturn.id + '?shouldValidate=1'}>
                                {t('errorsIn.label')} {t('step1.label')}
                            </Link>
                        </Div>}
                        {!this.state[2].valid && <Div>
                            <Link to={constants.ROUTES.WIZARD.STEP[2] + '/' + taxReturn.id + '?shouldValidate=1'}>
                                {t('errorsIn.label')} {t('step2.label')}
                            </Link>
                        </Div>}
                        {!this.state[3].valid && <Div>
                            <Link to={constants.ROUTES.WIZARD.STEP[3] + '/' + taxReturn.id + '?shouldValidate=1'}>
                                {t('errorsIn.label')} {t('step3.label')}
                            </Link>
                        </Div>}
                    </Div>
                </Div>}
                {this.isLoaded() && !this.hasErrors() && <Form>
                    {shouldValidate && <ValidationWrapper wizardService={wizardService} validateForm={validateForm} setFieldTouched={setFieldTouched}/>}
                    <Div className="formSection">
                        <h3>{t('okHeadline.label')}</h3>
                        {taxReturn.calculation.hasOwnProperty('tax') && <Div className="subForm">
                            <p>
                                <strong>
                                    {t('yourTaxIs.label')}{' '}
                                    <NumberFormat
                                        value={taxReturn.calculation.tax}
                                        thousandSeparator={thousandSeparator}
                                        suffix={czkSuffix}
                                        displayType="text"
                                    />
                                </strong>
                            </p>
                            <p>{t('yourTaxIs.placeholder')}</p>
                        </Div>}
                        {taxReturn.sections.selfEmployment && <RedP>
                            {t('okHeadline.placeholder')}
                        </RedP>}
                    </Div>
                    {taxReturn.calculation.tax < 0 && <Div className="formSection">
                        <h3>
                            <UniCheckbox
                                name="overPayment.returnRequired"
                                validateForm={validateForm}
                                placeholder={true}
                                taxReturn={taxReturn}
                            />
                        </h3>
                        {values.overPayment.returnRequired && <AccountForm
                            name="overPayment.account"
                            accountValue={values.overPayment.account}
                            required={values.overPayment.returnRequired}
                            whole={true}
                            validateForm={validateForm}
                            taxReturn={taxReturn}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            t={t}
                        />}
                    </Div>}

                    {taxReturn.sections.selfEmployment && <h3>{t('statementsHeadline.label')}</h3>}
                    {taxReturn.sections.selfEmployment && <p>{t('statementsHeadline.placeholder')}</p>}
                    {taxReturn.sections.selfEmployment && <Div className="formSection">
                        <h3>
                            <UniCheckbox
                                name="socialInsurance.summaryRequired"
                                validateForm={validateForm}
                                placeholder={true}
                                taxReturn={taxReturn}
                            />
                        </h3>
                        {values.socialInsurance.summaryRequired && <Div className="subForm">
                            <Row>
                                <Col xs="12" sm="4" lg="4">
                                    <UniField name="socialInsurance.variableSymbol" required={values.socialInsurance.summaryRequired} taxReturn={taxReturn} />
                                </Col>
                                <Col xs="12" sm="4" lg="4">
                                    <CatalogComboField
                                        catalogName="social-welfare-office"
                                        name="socialInsurance.socialSecurityOffice"
                                        required={values.socialInsurance.summaryRequired}
                                        value={taxReturn.socialInsurance.socialSecurityOffice}
                                        validateForm={validateForm}
                                        setFieldValue={setFieldValue}
                                        taxReturn={taxReturn}
                                    />
                                </Col>
                                <Col xs="12" sm="4" lg="4">
                                    <UniField name="socialInsurance.birthNumber" taxReturn={taxReturn} />
                                </Col>
                            </Row>
                            <h3>
                                {t('socialInsurance.monthsTitle.label')}{' '}
                                <HelpIcon helpKey="socialInsurance.monthsTitle" t={t} />
                            </h3>
                            <Div className="subForm">
                                <Row>
                                    <Col xs="12" sm="6" lg="6">
                                        <UniCheckbox
                                            name="socialInsurance.selfEmployedWholeYear"
                                            validateForm={validateForm}
                                            textKey="socialInsurance.wholeYear"
                                            errorTextKey="socialInsuranceInfo"
                                            taxReturn={taxReturn}
                                        />
                                    </Col>
                                </Row>
                                {!values.socialInsurance.selfEmployedWholeYear && <Row>
                                    {months.map((month) => (<Col key={month} xs="6" sm="4" md="3" lg="2">
                                        <UniCheckbox
                                            name={`socialInsurance.selfEmployedMonths.${month}`}
                                            validateForm={validateForm}
                                            textKey={`socialInsurance.months.${month}`}
                                            taxReturn={taxReturn}
                                        />
                                    </Col>))}
                                </Row>}
                            </Div>
                            {infoTypes.map((infoType) => (<Div key={infoType}>
                                <h3>
                                    <UniCheckbox
                                        name={`socialInsurance.${infoType}.selected`}
                                        errorTextKey="socialInsuranceInfo"
                                        validateForm={validateForm}
                                        placeholder={true}
                                        placeholderWhenTrue={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                                <Div className="subForm">
                                    {values.socialInsurance[infoType].selected && <Row>
                                        <Col xs="12" sm="6" lg="6">
                                            <UniCheckbox
                                                name={`socialInsurance.${infoType}.wholeYear`}
                                                validateForm={validateForm}
                                                textKey="socialInsurance.wholeYear"
                                                taxReturn={taxReturn}
                                            />
                                        </Col>
                                    </Row>}
                                    {values.socialInsurance[infoType].selected && !values.socialInsurance[infoType].wholeYear && <Row>
                                        {months.map((month) => (<Col key={month} xs="6" sm="4" md="3" lg="2">
                                            <UniCheckbox
                                                name={`socialInsurance.${infoType}.months.${month}`}
                                                validateForm={validateForm}
                                                textKey={`socialInsurance.months.${month}`}
                                                taxReturn={taxReturn}
                                            />
                                        </Col>))}
                                    </Row>}
                                </Div>
                            </Div>))}
                            <h3>
                                <UniCheckbox
                                    name="socialInsurance.childCare.selected"
                                    errorTextKey="socialInsuranceInfo"
                                    validateForm={validateForm}
                                    placeholder={true}
                                    placeholderWhenTrue={true}
                                    taxReturn={taxReturn}
                                />
                            </h3>
                            <Div className="subForm">
                                {values.socialInsurance.childCare.selected && <Row>
                                    <Col xs="12" sm="6" lg="6">
                                        <UniCheckbox
                                            name={`socialInsurance.childCare.wholeYear`}
                                            validateForm={validateForm}
                                            textKey="socialInsurance.wholeYear"
                                            taxReturn={taxReturn}
                                        />
                                    </Col>
                                </Row>}
                                {values.socialInsurance.childCare.selected && !values.socialInsurance.childCare.wholeYear && <Row>
                                    {months.map((month) => (<Col key={month} xs="6" sm="4" md="3" lg="2">
                                        <UniCheckbox
                                            name={`socialInsurance.childCare.months.${month}`}
                                            validateForm={validateForm}
                                            textKey={`socialInsurance.months.${month}`}
                                            taxReturn={taxReturn}
                                        />
                                    </Col>))}
                                </Row>}
                                {values.socialInsurance.childCare.selected && <Row>
                                    <Col xs="12" sm="4" lg="4">
                                        <UniField name="socialInsurance.childCare.firstChildBirthNumber" taxReturn={taxReturn} />
                                    </Col>
                                    <Col xs="12" sm="4" lg="4">
                                        <UniField name="socialInsurance.childCare.secondChildBirthNumber" taxReturn={taxReturn} />
                                    </Col>
                                </Row>}
                            </Div>
                            <SpacedDiv>
                                <Row>
                                    <Col xs="12" sm="4" lg="4">
                                        <UniField name="socialInsurance.paidAdvances" taxReturn={taxReturn} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="4" lg="4">
                                        <UniCheckbox
                                            name="socialInsurance.firstYearOfSelfEmployment"
                                            validateForm={validateForm}
                                            taxReturn={taxReturn}
                                        />
                                    </Col>
                                    <Col xs="12" sm="4" lg="4">
                                        <UniCheckbox
                                            name="socialInsurance.nextYearSideActivity"
                                            validateForm={validateForm}
                                            taxReturn={taxReturn}
                                        />
                                    </Col>
                                    <Col xs="12" sm="4" lg="4">
                                        <UniCheckbox
                                            name="socialInsurance.registeredToIllnessInsurance"
                                            validateForm={validateForm}
                                            taxReturn={taxReturn}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="4" lg="4">
                                        {taxReturn.year >= 2021 && <UniCheckbox
                                            name="socialInsurance.submitLaterElectronically"
                                            validateForm={validateForm}
                                            taxReturn={taxReturn}
                                        />}
                                    </Col>
                                    <Col xs="12" sm="4" lg="4">
                                        <UniCheckbox
                                            name="socialInsurance.hasNoActiveLicense"
                                            validateForm={validateForm}
                                            taxReturn={taxReturn}
                                        />
                                    </Col>
                                    {/* taxReturn.year >= 2022 && <Col xs="12" sm="4" lg="4">
                                        <UniCheckbox
                                            name="socialInsurance.nextYearLumpPayer"
                                            validateForm={validateForm}
                                            taxReturn={taxReturn}
                                        />
                                    </Col> */}
                                    {taxReturn.year > 2018 && taxReturn.year < 2022 && <Col xs="12" sm="4" lg="4">
                                        <UniCheckbox
                                            name={`socialInsurance.correctiveStatement.selected`}
                                            validateForm={validateForm}
                                            textKey="socialInsurance.correctiveStatement.selected"
                                            taxReturn={taxReturn}
                                        />
                                    </Col>}
                                    {taxReturn.year >= 2022 && <Col xs="12" sm="4" lg="4">
                                        <UniCheckbox
                                            name={`socialInsurance.correctiveStatement.selected`}
                                            validateForm={validateForm}
                                            textKey="socialInsurance.correctiveStatement.selected"
                                            taxReturn={taxReturn}
                                        />
                                    </Col>}
                                </Row>
                                {taxReturn.year > 2018 && values.socialInsurance.correctiveStatement && values.socialInsurance.correctiveStatement.selected && <Row>
                                  <Col xs="12" sm="4" lg="4">&nbsp;</Col>
                                  <Col xs="12" sm="4" lg="4">
                                      <MyDatePicker
                                          {...datePickerProps(
                                            'socialInsurance.correctiveStatement.date',
                                            values.socialInsurance.correctiveStatement.selected
                                          )}
                                      />
                                  </Col>
                                  <Col xs="12" sm="4" lg="4">
                                      <UniField
                                          required={values.socialInsurance.correctiveStatement.selected}
                                          name="socialInsurance.correctiveStatement.reason"
                                          taxReturn={taxReturn}
                                      />
                                  </Col>
                              </Row>}
                            </SpacedDiv>
                        </Div>}

                        {taxReturn.calculation.hasOwnProperty('social') && <Div className="formSection">
                            <Div className="subForm">
                                <p>
                                    <strong>
                                        {t('yourSocialIs.label')}{' '}
                                        <NumberFormat
                                            value={taxReturn.calculation.social}
                                            displayType="text"
                                            thousandSeparator={thousandSeparator}
                                            suffix={czkSuffix}
                                        />
                                    </strong>
                                </p>
                                <p>{t('yourSocialIs.placeholder')}</p>
                            </Div>

                            {taxReturn.calculation.social < 0 && <Div className="subForm">
                                <h3>
                                    <UniCheckbox
                                        name="socialInsurance.overPayment.returnRequired"
                                        validateForm={validateForm}
                                        textKey="overPayment.returnRequired"
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                                {values.socialInsurance.overPayment.returnRequired && <Div className="subForm">
                                    <p>{t('account.accountType.title.label')}</p>
                                    <AccountForm
                                        name="socialInsurance.overPayment.account"
                                        accountValue={values.socialInsurance.overPayment.account}
                                        required={values.socialInsurance.overPayment.returnRequired}
                                        whole={false}
                                        validateForm={validateForm}
                                        taxReturn={taxReturn}
                                        setFieldValue={setFieldValue}
                                        setFieldTouched={setFieldTouched}
                                        t={t}
                                    />
                                </Div>}
                            </Div>}
                        </Div>}
                    </Div>}

                    {taxReturn.sections.selfEmployment && values.socialInsurance.summaryRequired && <Div className="formSection">
                        <h3>
                            <UniCheckbox
                                name="socialInsurance.healthInsurance.summaryRequired"
                                validateForm={validateForm}
                                placeholder={true}
                                taxReturn={taxReturn}
                            />
                        </h3>
                        {values.socialInsurance.healthInsurance.summaryRequired && <Div className="subForm">
                            <Row>
                                <Col xs="12" sm="4" lg="4">
                                    <UniField name="socialInsurance.healthInsurance.insureeId" required={values.socialInsurance.healthInsurance.summaryRequired} taxReturn={taxReturn} />
                                </Col>
                                <Col xs="12" sm="4" lg="4">
                                    <HealthInsuranceOffice t={t} name="socialInsurance.healthInsurance.healthInsuranceOffice" required={values.socialInsurance.healthInsurance.summaryRequired}/>
                                </Col>
                                <Col xs="12" sm="4" lg="4">
                                    {values.socialInsurance.healthInsurance.healthInsuranceOffice === 'HI111' && <VzpBranch t={t} required={values.socialInsurance.healthInsurance.summaryRequired && values.socialInsurance.healthInsurance.healthInsuranceOffice === 'HI111'} name="socialInsurance.healthInsurance.vzpBranch" />}
                                </Col>
                            </Row>
                            <p>{t('socialInsurance.healthInsurance.bankAccount.label')}</p>
                            <AccountForm
                                name="socialInsurance.healthInsurance.bankAccount"
                                accountValue={values.socialInsurance.healthInsurance.bankAccount}
                                required={false}
                                whole={false}
                                validateForm={validateForm}
                                taxReturn={taxReturn}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                t={t}
                            />
                            <Row>
                                {taxReturn.year < 2020 && <Col xs="12" sm="6" lg="4">
                                    <NumberOfMonths name="socialInsurance.healthInsurance.monthsInsured" max={monthsInsuredMax} required={values.socialInsurance.healthInsurance.summaryRequired} t={t} taxReturn={taxReturn}/>
                                </Col>}
                                <Col xs="12" sm="4" lg="4">
                                    <UniField name="socialInsurance.healthInsurance.paidAdvances" taxReturn={taxReturn} />
                                </Col>
                            </Row>

                            {taxReturn.year >= 2020 && <h3>
                                {t('socialInsurance.healthInsurance.monthsInsuredInfo.label')}{' '}
                                <HelpIcon helpKey="socialInsurance.healthInsurance.monthsInsuredInfo" t={t} />
                            </h3>}
                            {taxReturn.year >= 2020 && <Div className="subForm">
                                <Row>
                                    <Col xs="12" sm="6" lg="6">
                                        {values.socialInsurance.selfEmployedWholeYear && <UniCheckbox
                                            name={`socialInsurance.healthInsurance.monthsInsuredInfo.wholeYear`}
                                            validateForm={validateForm}
                                            textKey="socialInsurance.wholeYear"
                                            taxReturn={taxReturn}
                                        />}
                                        {!values.socialInsurance.selfEmployedWholeYear && <UniDisabledCheckbox
                                            name={`socialInsurance.healthInsurance.monthsInsuredInfo.wholeYear`}
                                            textKey="socialInsurance.wholeYear"
                                            taxReturn={taxReturn}
                                        />}
                                    </Col>
                                </Row>
                                {(!values.socialInsurance.healthInsurance.monthsInsuredInfo.wholeYear || !values.socialInsurance.selfEmployedWholeYear) && <Row>
                                    {months.map((month) => (<Col key={month} xs="6" sm="4" md="3" lg="2">
                                        {(values.socialInsurance.selfEmployedMonths[month] || values.socialInsurance.selfEmployedWholeYear) && <UniCheckbox
                                            name={`socialInsurance.healthInsurance.monthsInsuredInfo.months.${month}`}
                                            validateForm={validateForm}
                                            textKey={`socialInsurance.months.${month}`}
                                            taxReturn={taxReturn}
                                        />}
                                        {!values.socialInsurance.selfEmployedMonths[month] && !values.socialInsurance.selfEmployedWholeYear && <UniDisabledCheckbox
                                            name={`socialInsurance.healthInsurance.monthsInsuredInfo.months.${month}`}
                                            textKey={`socialInsurance.months.${month}`}
                                            taxReturn={taxReturn}
                                        />}
                                    </Col>))}
                                </Row>}
                            </Div>}

                            {taxReturn.year >= 2022 && <h3>
                                {t('socialInsurance.healthInsurance.lumpPayerMonthsInfo.label', { taxReturnYear: taxReturn.year, followingYear: taxReturn.year + 1 })}{' '}
                                <HelpIcon helpKey="socialInsurance.healthInsurance.lumpPayerMonthsInfo" t={t} />
                            </h3>}
                            {taxReturn.year >= 2022 && <Div className="subForm">
                                <Row>
                                    <Col xs="12" sm="6" lg="6">
                                        {values.socialInsurance.selfEmployedWholeYear && <UniCheckbox
                                            name={`socialInsurance.healthInsurance.lumpPayerMonthsInfo.wholeYear`}
                                            validateForm={validateForm}
                                            textKey="socialInsurance.wholeYear"
                                            taxReturn={taxReturn}
                                        />}
                                        {!values.socialInsurance.selfEmployedWholeYear && <UniDisabledCheckbox
                                            name={`socialInsurance.healthInsurance.lumpPayerMonthsInfo.wholeYear`}
                                            textKey="socialInsurance.wholeYear"
                                            taxReturn={taxReturn}
                                        />}
                                    </Col>
                                </Row>
                                {(!values.socialInsurance.healthInsurance.lumpPayerMonthsInfo.wholeYear || !values.socialInsurance.selfEmployedWholeYear) && <Row>
                                    {months.map((month) => (<Col key={month} xs="6" sm="4" md="3" lg="2">
                                        {(values.socialInsurance.selfEmployedMonths[month] || values.socialInsurance.selfEmployedWholeYear) && <UniCheckbox
                                            name={`socialInsurance.healthInsurance.lumpPayerMonthsInfo.months.${month}`}
                                            validateForm={validateForm}
                                            textKey={`socialInsurance.months.${month}`}
                                            taxReturn={taxReturn}
                                        />}
                                        {!values.socialInsurance.selfEmployedMonths[month] && !values.socialInsurance.selfEmployedWholeYear && <UniDisabledCheckbox
                                            name={`socialInsurance.healthInsurance.lumpPayerMonthsInfo.months.${month}`}
                                            textKey={`socialInsurance.months.${month}`}
                                            taxReturn={taxReturn}
                                        />}
                                    </Col>))}
                                </Row>}
                            </Div>}

                            <Row>
                                <Col xs="12" sm="4" lg="4">
                                    <UniCheckbox
                                        name="socialInsurance.healthInsurance.noMinimumNextYear"
                                        validateForm={validateForm}
                                        taxReturn={taxReturn}
                                    />
                                </Col>
                                <Col xs="12" sm="4" lg="4">
                                    <UniCheckbox
                                        name="socialInsurance.healthInsurance.employedNextYear"
                                        validateForm={validateForm}
                                        taxReturn={taxReturn}
                                    />
                                </Col>
                                {taxReturn.year > 2018 && <Col xs="12" sm="4" lg="4">
                                    <UniCheckbox
                                        name="socialInsurance.healthInsurance.correctiveStatement"
                                        validateForm={validateForm}
                                        taxReturn={taxReturn}
                                    />
                                </Col>}
                            </Row>
                        </Div>}

                        {taxReturn.calculation.hasOwnProperty('health') && <Div className="formSection">
                            <Div className="subForm">
                                <p>
                                    <strong>
                                        {t('yourHealthIs.label')}{' '}
                                        <NumberFormat
                                            value={taxReturn.calculation.health}
                                            thousandSeparator={thousandSeparator}
                                            suffix={czkSuffix}
                                            displayType="text"
                                        />
                                    </strong>
                                </p>
                                <p>{t('yourHealthIs.placeholder')}</p>
                            </Div>

                            {taxReturn.calculation.health < 0 && <Div className="subForm">
                                <h3>
                                    <UniCheckbox
                                        name="socialInsurance.healthInsurance.returnRequired"
                                        validateForm={validateForm}
                                        textKey="overPayment.returnRequired"
                                        placeholder={false}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                            </Div>}
                        </Div>}
                    </Div>}

                    <Div className="formSection">
                        <h3>
                            <UniCheckbox
                                name="visa.infoRequired"
                                validateForm={validateForm}
                                placeholder={false}
                                taxReturn={taxReturn}
                            />
                        </h3>
                        {values.visa.infoRequired && <Div className="subForm">
                            <p>{t('visa.applicationType.title.label')}*</p>
                            <Row>
                                <Col xs="12" sm="6" lg="4">
                                    <UniRadio
                                        name="visa.applicationType"
                                        validateForm={validateForm}
                                        textKey="visa.applicationTypes.SINGLE"
                                        value="SINGLE"
                                        required={values.visa.infoRequired}
                                        showsError={true}
                                        errorTextKey="visa.applicationType"
                                        taxReturn={taxReturn}
                                    />
                                </Col>
                                <Col xs="12" sm="6" lg="4">
                                    <UniRadio
                                        name="visa.applicationType"
                                        validateForm={validateForm}
                                        textKey="visa.applicationTypes.JOINT"
                                        value="JOINT"
                                        required={values.visa.infoRequired}
                                        taxReturn={taxReturn}
                                    />
                                </Col>
                                <Col xs="12" sm="6" lg="4">
                                    {values.visa.applicationType == 'JOINT' && <UniField name="visa.numberOfPersons" required={values.visa.infoRequired && values.visa.applicationType == 'JOINT'} taxReturn={taxReturn} />}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="6" lg="4">
                                    <UniField name="visa.monthlyHousingCosts" taxReturn={taxReturn} />
                                </Col>
                                <Col xs="12" sm="6" lg="6">
                                    <NumberOfMonths
                                        name="visa.monthsVisa"
                                        required={values.visa.infoRequired}
                                        validateForm={validateForm}
                                        taxReturn={taxReturn}
                                    />
                                </Col>
                            </Row>
                            {taxReturn.calculation.hasOwnProperty('visaNetIncome') && <Row>
                                <Col xs="12" sm="12" lg="12">
                                    <p>{t('visa.yourNetIncomeIs.label')}<br />
                                        <strong>
                                            <NumberFormat
                                                value={taxReturn.calculation.visaNetIncome}
                                                thousandSeparator={thousandSeparator}
                                                suffix={czkSuffix}
                                                displayType="text"
                                            />
                                        </strong><br />
                                        {t('visa.yourMinimumIs.label')}<br />
                                        <strong>
                                            <NumberFormat
                                                value={taxReturn.calculation.visaMinimum}
                                                thousandSeparator={thousandSeparator}
                                                suffix={czkSuffix}
                                                displayType="text"
                                            />
                                        </strong>
                                    </p>
                                    {taxReturn.calculation.visaNetIncome >= taxReturn.calculation.visaMinimum && <p>
                                        <strong>
                                            <FontAwesomeIcon icon={faSmile}/>{' '}
                                            {t('visa.isHigher.label')}
                                        </strong>
                                    </p>}
                                    {taxReturn.calculation.visaNetIncome < taxReturn.calculation.visaMinimum && <Div>
                                        <RedP>
                                            <strong>
                                                <FontAwesomeIcon icon={faFrown}/>{' '}
                                                {t('visa.isLower.label')}
                                            </strong>
                                        </RedP>
                                        <p>
                                            {t('visa.isLower.placeholder')}
                                            <HelpLink helpKey="visa.isLowerHelp" taxReturn={taxReturn} t={t} />
                                        </p>
                                        <Div>
                                            <UniCheckbox
                                                name="visa.isAwareOf"
                                                validateForm={validateForm}
                                                placeholder={false}
                                                taxReturn={taxReturn}
                                            />
                                        </Div>
                                    </Div>}
                                </Col>
                            </Row>}
                        </Div>}
                    </Div>

                    <Row>
                        <Col xs="12" sm="6" lg="4">
                            <Button onClick={() => wizardService.backToDashboard()}>
                                <FontAwesomeIcon icon={faHome} />{' '}
                                {t('buttons.backToDashboard.label')}
                            </Button>
                        </Col>
                        <Col xs="12" sm="6" lg="4">
                            <Button onClick={() => wizardService.previousStep()}>
                                <FontAwesomeIcon icon={faArrowCircleLeft} />{' '}
                                {t('buttons.previous.label')}
                            </Button>
                        </Col>
                        <Col xs="12" sm="6" lg="4">
                            <Button className="btn-primary" onClick={() => wizardService.nextStep(validateForm, setFieldTouched, t)}>
                                {t('buttons.next.label')}{' '}
                                <FontAwesomeIcon icon={faArrowCircleRight} />
                            </Button>
                        </Col>
                    </Row>
                </Form>}
            </Div>)
        }}
        </Formik>
        );
	}
}

const RedP = glamorous('p')({
    color: theme.colors.red,
});

const SpacedDiv = glamorous(Div)({
    marginTop: '24px',
});

export default translate('translations')(StepFourForm);
