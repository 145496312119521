import {faHome, faArrowCircleLeft, faWallet, faDownload, faSpinner} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import glamorous,{Div} from 'glamorous';
import { get } from 'lodash';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import {Button, Col, Row} from 'reactstrap';
import { IStepProps } from '../../interfaces/IStepProps';
import { WizardService } from '../../services/WizardService';
import * as paymentService from '../../services/paymentService';
import constants from "../../../../utils/constants";
import history from '../../../../utils/history';
import UniCheckbox from '../UniFields/UniCheckbox';
import UniField from '../UniFields/UniField';
import { OTHER_INCOME_TYPES } from '../StepOne/FormFields/OtherIncomeType';
import apiConfig from "../../../../config/apiConfig";
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import Attachments from "../../../../components/Attachments/Attachments";
import ValidationWrapper from "../../../../components/ValidationWrapper/ValidationWrapper";
import axios from 'axios';
import fileDownload from 'js-file-download';
import theme from '../../../../styles/theme';
import CatalogLabel from "../../../../components/CatalogComboField/CatalogLabel";
import EmploymentsSummary from "./components/EmploymentsSummary";
import SelfEmploymentSummary from "./components/SelfEmploymentSummary";
import PersonalDataSummary from "./components/PersonalDataSummary";
import AddressSummary from "./components/AddressSummary";
import AbroadIncomesSummary from "./components/AbroadIncomesSummary";

interface IStepFiveProps {
    certifiedCheckAttachments: any;
    loadCertifiedCheckAttachments: Function;
    onMyBehalfAttachments: any;
    loadOnMyBehalfAttachments: Function;
    attachFile: Function;
    deleteAttachment: Function;
}

interface IStepFiveState {
    certifiedCheckAttachmentsLoaded: boolean;
    onMyBehalfAttachmentsLoaded: boolean;
    poaDownloading: boolean;
    nonWorkingSpouseDeclarationDownloading: boolean;
    secondParentDeclarationDownloading: boolean;
}

class StepFiveForm extends React.Component<WithNamespaces & IStepProps & IStepFiveProps, IStepFiveState> {

    readonly state = {
        certifiedCheckAttachmentsLoaded: false,
        onMyBehalfAttachmentsLoaded: false,
        poaDownloading: false,
        nonWorkingSpouseDeclarationDownloading: false,
        secondParentDeclarationDownloading: false,
    };

    constructor(props) {
        super(props);
        this.goPay = this.goPay.bind(this);
    }

    componentDidMount() {
        const { taxReturn } = this.props;

        if (taxReturn.state !== 'paid') {
            paymentService.loadGoPayApi();
        }
    }

    componentDidUpdate() {
        const { taxReturn, loadCertifiedCheckAttachments, loadOnMyBehalfAttachments } = this.props;

        if (taxReturn.certifiedCheckRequired && !this.state.certifiedCheckAttachmentsLoaded) {
            loadCertifiedCheckAttachments(taxReturn.id);
            this.setState((oldState) => ({
                certifiedCheckAttachmentsLoaded: true,
            }));
        }

        if (taxReturn.onMyBehalfRequired && !this.state.onMyBehalfAttachmentsLoaded) {
            loadOnMyBehalfAttachments(taxReturn.id);
            this.setState((oldState) => ({
                onMyBehalfAttachmentsLoaded: true,
            }));
        }

    };

    goToDownload = () => {
        history.push('/download/' + this.props.taxReturn.id);
    };

    downloadPoa = () => {
        if (this.state.poaDownloading) {
            return;
        }

        this.setState((oldState) => ({
            ...oldState,
            poaDownloading: true,
        }));
        axios.get(
            `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/power-of-attorney-pdf`,
            { responseType: 'blob', withCredentials: true }
        ).then((response) => {
            this.setState((oldState) => ({
                ...oldState,
                poaDownloading: false
            }));
            fileDownload(response.data, this.props.t(`download.poa.label`) + '.pdf');
        });
    };

    downloadNonWorkingSpouseDeclaration = () => {
        if (this.state.nonWorkingSpouseDeclarationDownloading) {
            return;
        }

        this.setState((oldState) => ({
            ...oldState,
            nonWorkingSpouseDeclarationDownloading: true,
        }));
        axios.get(
            `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/non-working-spouse-declaration-pdf`,
            { responseType: 'blob', withCredentials: true }
        ).then((response) => {
            this.setState((oldState) => ({
                ...oldState,
                nonWorkingSpouseDeclarationDownloading: false
            }));
            fileDownload(response.data, this.props.t(`download.non_working_spouse_declaration.label`) + '.pdf');
        });
    };

    downloadSecondParentDeclaration = () => {
        if (this.state.secondParentDeclarationDownloading) {
            return;
        }

        this.setState((oldState) => ({
            ...oldState,
            secondParentDeclarationDownloading: true,
        }));
        axios.get(
            `${apiConfig.url}/tax-returns/${this.props.taxReturn.id}/second-parent-declaration-pdf`,
            { responseType: 'blob', withCredentials: true }
        ).then((response) => {
            this.setState((oldState) => ({
                ...oldState,
                secondParentDeclarationDownloading: false
            }));
            fileDownload(response.data, this.props.t(`download.second_parent_declaration.label`) + '.pdf');
        });
    };

	render() {
        const { t, taxReturn, lang, update, validate, shouldValidate,
            certifiedCheckAttachments, onMyBehalfAttachments, attachFile, deleteAttachment } = this.props;

        const thousandSeparator = lang === 'cs' ? ' ' : ',';
        const czkSuffix = lang === 'cs' ? ' Kč' : ' CZK';

        if (!taxReturn.year) {
            return null;
        } else {
            this.props.validate(taxReturn.id, 4)
                .catch((errors) => {
                    history.push(constants.ROUTES.WIZARD.STEP[4] + '/' + taxReturn.id + '?shouldValidate=1');
                });
        }

        const wizardService = new WizardService(5, taxReturn.id, update, validate, t);

        return (<Formik
            initialValues={taxReturn}
            onSubmit={values => console.log(values)}
            validateOnChange={false}
            validate={wizardService.validateAndSave}
        >
            {({values, validateForm, setFieldTouched}) => (<Div>
                <Div className="navButtons">
                    <Button title={t('buttons.backToDashboard.label')} onClick={() => wizardService.backToDashboard()}>
                        <FontAwesomeIcon icon={faHome} />
                    </Button>
                    <Button title={t('buttons.previous.label')} onClick={() => wizardService.previousStep()}>
                        <FontAwesomeIcon icon={faArrowCircleLeft} />
                    </Button>
                    {this.props.taxReturn.state === 'created' && <Button title={t('buttons.pay.label')}className="btn-primary" onClick={() => wizardService.goPay(validateForm, setFieldTouched, t, this.goPay)}>
                        <FontAwesomeIcon  icon={faWallet} />
                    </Button>}
                    {this.props.taxReturn.state === 'paid' && <Button title={t('buttons.download.label')} className="btn-primary" onClick={this.goToDownload}>
                        <FontAwesomeIcon icon={faDownload} />
                    </Button>}
                </Div>
                <h1>{taxReturn.personalData.firstName} {taxReturn.personalData.lastName} {taxReturn.year}</h1>
                <Div className="formSection">
                    <h3 className="noCheckbox">{t('summary.title.label')}</h3>
                    <p className="important">{t('summary.title.placeholder')}</p>
                </Div>

                {taxReturn.sections.employments && <EmploymentsSummary
                    employments={taxReturn.employments}
                    taxReturnId={taxReturn.id}
                    t={t}
                    thousandSeparator={thousandSeparator}
                    czkSuffix={czkSuffix}
                    year={taxReturn.year}
                />}

                {taxReturn.sections.selfEmployment && <SelfEmploymentSummary
                    selfEmployment={taxReturn.selfEmployment}
                    taxReturnId={taxReturn.id}
                    thousandSeparator={thousandSeparator}
                    czkSuffix={czkSuffix}
                    t={t}
                />}

                {taxReturn.sections.leaseIncome && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.leaseIncome.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6" lg="4">
                                {t('leaseIncome.totalIncome.label')}<br/>
                                <strong><NumberFormat value={taxReturn.leaseIncome.totalIncome} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                            <Col xs="12" sm="6" lg="4">
                                {t('leaseIncome.realEstateIncome.label')}<br/>
                                <strong><NumberFormat value={taxReturn.leaseIncome.realEstateIncome} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </SummaryRow>
                        <SummaryRow>
                            <Col xs="12" sm="6" lg="4">
                                {t('leaseIncome.expensesType.label')}<br/>
                                <strong>{t(`leaseIncome.expensesTypes.${taxReturn.leaseIncome.expensesType}.label`)}</strong>
                            </Col>
                            {taxReturn.leaseIncome.expensesType === 'real' && <Col xs="12" sm="6" lg="4">
                                {t('leaseIncome.expensesType.label')}<br/>
                                <strong><NumberFormat value={taxReturn.leaseIncome.expenses} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>}
                        </SummaryRow>
                        <Link to={constants.ROUTES.WIZARD.STEP[1] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.sections.capitalAssetsIncome && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.capitalAssetsIncome.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6" lg="4">
                                {t('capitalAssetsIncome.label')}<br/>
                                <strong><NumberFormat value={taxReturn.capitalAssetsIncome} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </SummaryRow>
                        <Link to={constants.ROUTES.WIZARD.STEP[1] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.sections.abroadIncomes && <AbroadIncomesSummary
                    abroadIncomes={taxReturn.abroadIncomes}
                    taxReturnId={taxReturn.id}
                    thousandSeparator={thousandSeparator}
                    czkSuffix={czkSuffix}
                    t={t}
                />}

                {taxReturn.sections.otherIncomes && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.otherIncomes.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6" lg="3">
                                {t('otherIncomes.type.label')}
                            </Col>
                            <Col xs="12" sm="6" lg="3">
                                {t('otherIncomes.income.label')}
                            </Col>
                            <Col xs="12" sm="6" lg="3">
                                {t('otherIncomes.expenses.label')}
                            </Col>
                            <Col xs="12" sm="6" lg="3">
                                {t('otherIncomes.generatedAbroad.label')}
                            </Col>
                        </SummaryRow>
                        {taxReturn.otherIncomes.map((income, index) => (
                            <SummaryRow key={index}>
                                <Col xs="12" sm="6" lg="3">
                                    <strong>{taxReturn.otherIncomes[index].type}</strong>
                                    {taxReturn.otherIncomes[index].type === OTHER_INCOME_TYPES.OTHER && <Div>
                                        <strong>{taxReturn.otherIncomes[index].name}</strong>
                                    </Div>}
                                </Col>
                                <Col xs="12" sm="6" lg="3">
                                    <strong><NumberFormat value={taxReturn.otherIncomes[index].income} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                                </Col>
                                <Col xs="12" sm="6" lg="3">
                                    <strong><NumberFormat value={taxReturn.otherIncomes[index].expenses} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                                </Col>
                                <Col xs="12" sm="6" lg="3">
                                    <strong>{taxReturn.otherIncomes[index].generatedAbroad ? t('yes.label') : t('no.label')}</strong>
                                </Col>
                            </SummaryRow>
                        ))}
                        <Link to={constants.ROUTES.WIZARD.STEP[1] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                <PersonalDataSummary personalData={taxReturn.personalData} taxReturnId={taxReturn.id} t={t}/>

                <AddressSummary
                    address={taxReturn.permanentAddress}
                    type="permanentAddress"
                    taxReturnId={taxReturn.id}
                    t={t}
                />

                {taxReturn.sections.czechResidencyAddress && <AddressSummary
                    address={taxReturn.czechResidencyAddress}
                    type="czechResidencyAddress"
                    taxReturnId={taxReturn.id}
                    t={t}
                />}

                {taxReturn.sections.lastDayAddress && <AddressSummary
                    address={taxReturn.lastDayAddress}
                    type="lastDayAddress"
                    taxReturnId={taxReturn.id}
                    t={t}
                />}

                <Div className="formSection">
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6" md="4">
                                {t('taxOffice.label')}<br/>
                                <strong>{taxReturn.taxOffice}</strong>
                            </Col>
                            {taxReturn.taxNumber && <Col xs="12" sm="6" md="4">
                                {t('taxNumber.label')}<br/>
                                <strong>{taxReturn.taxNumber}</strong>
                            </Col>}
                            {taxReturn.birthNumber && <Col xs="12" sm="6" md="4">
                                {t('birthNumber.label')}<br/>
                                <strong>{taxReturn.birthNumber}</strong>
                            </Col>}
                        </SummaryRow>
                        <SummaryRow>
                            <Col xs="12" sm="6" md="4">
                                {t('regionalOffice.label')}<br/>
                                <strong>{taxReturn.regionalOffice}</strong>
                            </Col>
                        </SummaryRow>
                        <SummaryRow>
                            <Col xs="12" sm="6">
                                {t('socialInsurance.nextYearLumpPayer.label')}<br />
                                <strong>
                                    {t(taxReturn.socialInsurance.nextYearLumpPayer ? 'yes.label' : 'no.label')}
                                </strong>
                            </Col>
                        </SummaryRow>
                        <SummaryRow>
                            <Col xs="12" sm="6">
                                {t('hasDatabox.label')}<br />
                                <strong>
                                    {taxReturn.hasDatabox
                                        ? taxReturn.databoxId
                                        : t('no.label')
                                    }
                                </strong>
                            </Col>
                        </SummaryRow>
                        <SummaryRow>
                            <Col xs="12" sm="6">
                                {t('submitViaDatabox.label')}<br />
                                <strong>
                                    {t(taxReturn.submitViaDatabox ? 'yes.label' : 'no.label')}
                                </strong>
                            </Col>
                        </SummaryRow>
                        {taxReturn.correctiveTaxReturn && <p><strong>{t('correctiveTaxReturn.label')}</strong></p>}
                        <div>
                            <Link to={constants.ROUTES.WIZARD.STEP[2] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                        </div>
                    </Div>
                </Div>

                {taxReturn.supplementaryStatement.selected && <Div className="formSection">
                    <h3 className="noCheckbox">{t('supplementaryStatement.selected.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6" md="4">
                                {t('supplementaryStatement.date.label')}<br/>
                                <strong>{taxReturn.supplementaryStatement.date}</strong>
                            </Col>
                            <Col xs="12" sm="6" md="4">
                                {t('supplementaryStatement.originalTax.label')}<br/>
                                <strong><NumberFormat value={taxReturn.supplementaryStatement.originalTax} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                            <Col xs="12" sm="6" md="4">
                                {t('supplementaryStatement.reason.label')}<br/>
                                <strong>{taxReturn.supplementaryStatement.reason}</strong>
                            </Col>
                        </SummaryRow>
                        <Link to={constants.ROUTES.WIZARD.STEP[2] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.sections.nonCzTaxResident && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.nonCzTaxResident.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6" md="4">
                                {t('nonCzTaxResident.country.label')}<br/>
                                <strong>{taxReturn.nonCzTaxResident.country}</strong>
                            </Col>
                            <Col xs="12" sm="6" md="4">
                                {t('nonCzTaxResident.passportNumber.label')}<br/>
                                <strong>{taxReturn.nonCzTaxResident.passportNumber}</strong>
                            </Col>
                            <Col xs="12" sm="6" md="4">
                                {t('nonCzTaxResident.worldWideIncome.label')}<br/>
                                <strong><NumberFormat value={taxReturn.nonCzTaxResident.worldWideIncome} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </SummaryRow>
                        <Link to={constants.ROUTES.WIZARD.STEP[2] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.sections.donation && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.donation.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6" lg="6">
                                {t('deductibles.donation.label')}<br/>
                                <strong><NumberFormat value={taxReturn.deductibles.donation} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </SummaryRow>
                        <Link to={constants.ROUTES.WIZARD.STEP[3] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.sections.mortgage && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.mortgage.label')}</h3>
                    <Div className="subForm">
                        {taxReturn.sections.mortgageRent && <h3 className="noCheckbox">{t('sections.mortgageRent.label')}</h3>}
                        {!taxReturn.sections.mortgageRent && <SummaryRow>
                            <Col xs="12" sm="6">
                                {t('deductibles.mortgage.interests.label')}<br/>
                                <strong><NumberFormat value={taxReturn.deductibles.mortgage.interests} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                            <Col xs="12" sm="6">
                                {t('deductibles.mortgage.numberOfMonths.label')}<br/>
                                <strong>{taxReturn.deductibles.mortgage.numberOfMonths}</strong>
                            </Col>
                        </SummaryRow>}
                        <Link to={constants.ROUTES.WIZARD.STEP[3] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.sections.pensionInsurance && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.pensionInsurance.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6">
                                {t('deductibles.pensionInsurance.contracts.label')}<br/>
                                <strong>{taxReturn.deductibles.pensionInsurance.contracts}</strong>
                            </Col>
                            <Col xs="12" sm="6">
                                {t('deductibles.pensionInsurance.amount.label')}<br/>
                                <strong><NumberFormat value={taxReturn.deductibles.pensionInsurance.amount} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </SummaryRow>
                        <Link to={constants.ROUTES.WIZARD.STEP[3] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.sections.lifeInsurance && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.lifeInsurance.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6">
                                {t('deductibles.lifeInsurance.contracts.label')}<br/>
                                <strong>{taxReturn.deductibles.lifeInsurance.contracts}</strong>
                            </Col>
                            <Col xs="12" sm="6">
                                {t('deductibles.lifeInsurance.amount.label')}<br/>
                                <strong><NumberFormat value={taxReturn.deductibles.lifeInsurance.amount} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </SummaryRow>
                        <Link to={constants.ROUTES.WIZARD.STEP[3] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.sections.longTermCareInsurance && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.longTermCareInsurance.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6">
                                {t('deductibles.longTermCareInsurance.contracts.label')}<br/>
                                <strong>{taxReturn.deductibles.longTermCareInsurance.contracts}</strong>
                            </Col>
                            <Col xs="12" sm="6">
                                {t('deductibles.longTermCareInsurance.amount.label')}<br/>
                                <strong><NumberFormat value={taxReturn.deductibles.longTermCareInsurance.amount} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </SummaryRow>
                        <Link to={constants.ROUTES.WIZARD.STEP[3] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.sections.longTermInvestmentProduct && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.longTermInvestmentProduct.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6">
                                {t('deductibles.longTermInvestmentProduct.contracts.label')}<br/>
                                <strong>{taxReturn.deductibles.longTermInvestmentProduct.contracts}</strong>
                            </Col>
                            <Col xs="12" sm="6">
                                {t('deductibles.longTermInvestmentProduct.amount.label')}<br/>
                                <strong><NumberFormat value={taxReturn.deductibles.longTermInvestmentProduct.amount} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </SummaryRow>
                        <Link to={constants.ROUTES.WIZARD.STEP[3] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.sections.kindergarten && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.kindergarten.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6">
                                {t('deductibles.kindergarten.label')}<br/>
                                <strong><NumberFormat value={taxReturn.deductibles.kindergarten} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </SummaryRow>
                        <Link to={constants.ROUTES.WIZARD.STEP[3] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.sections.student && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.student.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6">
                                {t('deductibles.student.label')}<br/>
                                <strong>{taxReturn.deductibles.student}</strong>
                            </Col>
                        </SummaryRow>
                        <Link to={constants.ROUTES.WIZARD.STEP[3] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.sections.married && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.married.label')}</h3>
                    {taxReturn.sections.commonHousehold && taxReturn.sections.partners && <Div className="subForm">
                        <h3 className="noCheckbox">{t('sections.commonHousehold.label')}</h3>
                        <h3 className="noCheckbox">{t('sections.partners.label')}</h3>
                        <SummaryRow>
                            <Col xs="12" sm="6" lg="3">
                                {t('deductibles.marriage.spouseFirstName.label')}<br/>
                                <strong>{taxReturn.deductibles.marriage.spouseFirstName}</strong>
                            </Col>
                            <Col xs="12" sm="6" lg="3">
                                {t('deductibles.marriage.spouseLastName.label')}<br/>
                                <strong>{taxReturn.deductibles.marriage.spouseLastName}</strong>
                            </Col>
                            <Col xs="12" sm="6" lg="3">
                                {t('deductibles.marriage.birthNumber.label')}<br/>
                                <strong>{taxReturn.deductibles.marriage.birthNumber}</strong>
                            </Col>
                            <Col xs="12" sm="6" lg="3">
                                {t('deductibles.marriage.numberOfMonths.label')}<br/>
                                <strong>{taxReturn.deductibles.marriage.numberOfMonths}</strong>
                            </Col>
                        </SummaryRow>
                        <SummaryRow>
                            <Col xs="12" sm="6">
                                {t('deductibles.marriage.ztpLicense.label')}<br/>
                                <strong>{taxReturn.deductibles.marriage.ztpLicense ? t('yes.label') : t('no.label')}</strong>
                            </Col>
                        </SummaryRow>
                        <Link to={constants.ROUTES.WIZARD.STEP[3] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>}
                </Div>}

                {taxReturn.sections.children && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.children.label')}</h3>
                    {taxReturn.sections.secondParentClaim && <Div className="subForm">
                        <h3 className="noCheckbox">{t('sections.secondParentClaim.label')}</h3>
                        <Div>
                            <SummaryRow>
                                <Col xs="12" sm="6" lg="3">
                                    {t('deductibles.children.firstName.label')}
                                </Col>
                                <Col xs="12" sm="6" lg="3">
                                    {t('deductibles.children.lastName.label')}
                                </Col>
                                <Col xs="12" sm="4" lg="2">
                                    {t('deductibles.children.birthNumber.label')}
                                </Col>
                                <Col xs="12" sm="4" lg="2">
                                    {t('deductibles.children.numberOfMonths.label')}
                                </Col>
                                <Col xs="12" sm="4" lg="2">
                                    {t('deductibles.children.ztpLicense.label')}
                                </Col>
                            </SummaryRow>
                            {taxReturn.deductibles.children.map((employment, index) => (
                                <SummaryRow key={index}>
                                    <Col xs="12" sm="6" lg="3">
                                        <strong>{taxReturn.deductibles.children[index].firstName}</strong>
                                    </Col>
                                    <Col xs="12" sm="6" lg="3">
                                        <strong>{taxReturn.deductibles.children[index].lastName}</strong>
                                    </Col>
                                    <Col xs="12" sm="4" lg="2">
                                        <strong>{taxReturn.deductibles.children[index].birthNumber}</strong>
                                    </Col>
                                    <Col xs="12" sm="4" lg="2">
                                        <strong>{taxReturn.deductibles.children[index].numberOfMonths}</strong>
                                    </Col>
                                    <Col xs="12" sm="4" lg="2">
                                        <strong>{taxReturn.deductibles.children[index].ztpLicense ? t('yes.label') : t('no.label')}</strong>
                                    </Col>
                                </SummaryRow>))}
                        </Div>
                        <Link to={constants.ROUTES.WIZARD.STEP[3] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>}
                </Div>}

                {taxReturn.sections.paidAdvances && <Div className="formSection">
                    <h3 className="noCheckbox">{t('sections.paidAdvances.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="6">
                                {t('deductibles.paidAdvances.label')}<br/>
                                <strong><NumberFormat value={taxReturn.deductibles.paidAdvances} displayType="text" thousandSeparator={thousandSeparator} suffix={czkSuffix} /></strong>
                            </Col>
                        </SummaryRow>
                        <Link to={constants.ROUTES.WIZARD.STEP[3] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.overPayment.returnRequired && <Div className="formSection">
                    <h3 className="noCheckbox">{t('overPayment.returnRequired.label')}</h3>
                    <Div className="subForm">
                        <SummaryRow>
                            <Col xs="12" sm="12" lg="12">
                                {t('account.accountType.title.label')}<br/>
                                <strong>{t('account.accountType.' + (taxReturn.overPayment.account.accountType === 'DOMESTIC' ? 'domestic' : 'foreign') + '.label')}</strong>
                            </Col>
                        </SummaryRow>
                        {taxReturn.overPayment.account.accountType === 'DOMESTIC' && <SummaryRow>
                            <Col xs="12" sm="3" lg="3">
                                {t('account.domesticAccount.prefix.label')}<br/>
                                <strong>{taxReturn.overPayment.account.domesticAccount.prefix}</strong>
                            </Col>
                            <Col xs="12" sm="6" lg="5">
                                {t('account.domesticAccount.number.label')}<br/>
                                <strong>{taxReturn.overPayment.account.domesticAccount.number}</strong>
                            </Col>
                            <Col xs="12" sm="3" lg="2">
                                {t('account.domesticAccount.code.label')}<br/>
                                <strong>{taxReturn.overPayment.account.domesticAccount.code}</strong>
                            </Col>
                            <Col xs="12" sm="3" lg="2">
                                {t('account.domesticAccount.currency.label')}<br/>
                                <strong>{taxReturn.overPayment.account.domesticAccount.currency}</strong>
                            </Col>
                            <Col xs="12" sm="4" lg="5">
                                {t('account.domesticAccount.bankName.label')}<br/>
                                <strong>{taxReturn.overPayment.account.domesticAccount.bankName}</strong>
                            </Col>
                            <Col xs="12" sm="5" lg="5">
                                {t('account.domesticAccount.accountHolder.label')}<br/>
                                <strong>{taxReturn.overPayment.account.domesticAccount.accountHolder}</strong>
                            </Col>
                        </SummaryRow>}
                        {taxReturn.overPayment.account.accountType === 'FOREIGN' && <SummaryRow>
                            <Col xs="12" sm="7" lg="6">
                                {t('account.foreignAccount.iban.label')}<br/>
                                <strong>{taxReturn.overPayment.account.foreignAccount.iban}</strong>
                            </Col>
                            <Col xs="12" sm="5" lg="4">
                                {t('account.foreignAccount.swift.label')}<br/>
                                <strong>{taxReturn.overPayment.account.foreignAccount.swift}</strong>
                            </Col>
                            <Col xs="12" sm="4" lg="2">
                                {t('account.foreignAccount.currency.label')}<br/>
                                <strong>{taxReturn.overPayment.account.foreignAccount.currency}</strong>
                            </Col>
                            <Col xs="12" sm="8" lg="6">
                                {t('account.foreignAccount.bankName.label')}<br/>
                                <strong>{taxReturn.overPayment.account.foreignAccount.bankName}</strong>
                            </Col>
                            <Col xs="12" sm="12" lg="6">
                                {t('account.foreignAccount.accountHolderNameAndAddress.label')}<br/>
                                <strong>{taxReturn.overPayment.account.foreignAccount.accountHolderNameAndAddress}</strong>
                            </Col>
                        </SummaryRow>}
                        <Link to={constants.ROUTES.WIZARD.STEP[4] + '/' + taxReturn.id}>{t('editThisSection.label')}</Link>
                    </Div>
                </Div>}

                {taxReturn.state === 'paid' && <Div className="additionalServices">
                    <h3>{t('additionalService.title.label')}</h3>
                    <Div className="formSection">
                        <h3 className="noCheckbox">{t('certifiedCheckRequired.label')}</h3>
                        <Div className="subForm">
                            <SummaryRow>
                                <Col>
                                    <strong>{t(taxReturn.certifiedCheckRequired ? 'yes.label' : 'no.label')}</strong>
                                </Col>
                            </SummaryRow>
                        </Div>
                        <h3 className="noCheckbox">{t('printingRequired.label')}</h3>
                        <Div className="subForm">
                            <SummaryRow>
                                <Col>
                                    <strong>{t(taxReturn.printingRequired ? 'yes.label' : 'no.label')}</strong>
                                </Col>
                            </SummaryRow>
                        </Div>
                        <h3 className="noCheckbox">{t('onMyBehalfRequired.label')}</h3>
                        <Div className="subForm">
                            <SummaryRow>
                                <Col>
                                    <strong>{t(taxReturn.onMyBehalfRequired ? 'yes.label' : 'no.label')}</strong>
                                </Col>
                            </SummaryRow>
                        </Div>
                    </Div>
                </Div>}

                <Form>
                    {shouldValidate && <ValidationWrapper wizardService={wizardService} validateForm={validateForm} setFieldTouched={setFieldTouched}/>}
                    {taxReturn.state === 'created' && <Div>
                        <Div className="formSection">
                            <Div className="subForm">
                                <Row>
                                    <Col xs="12" sm={{size: 6, offset: 6}} lg={{size: 3, offset: 9}}>
                                        <UniField name="discountCode" taxReturn={taxReturn} />
                                    </Col>
                                </Row>
                            </Div>
                        </Div>

                        <Div className="additionalServices">
                            <h3>{t('additionalService.title.label')}</h3>
                            <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name="certifiedCheckRequired"
                                        validateForm={validateForm}
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                                {values.certifiedCheckRequired && <Div className="subForm">
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="certifiedCheckNote" taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                    {certifiedCheckAttachments && Object.keys(certifiedCheckAttachments).length > 0 && <Div>
                                        <p><strong>{t('download.certifiedCheckAttachments.title.label')}</strong></p>
                                        <p>{t('download.certifiedCheckAttachments.title.placeholder')}</p>
                                        <Attachments
                                            attachments={certifiedCheckAttachments}
                                            taxReturn={taxReturn}
                                            attachFile={attachFile}
                                            deleteAttachment={deleteAttachment}
                                            t={t}
                                        />
                                    </Div>}
                                </Div>}
                            </Div>

                            <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name="printingRequired"
                                        validateForm={validateForm}
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                                {values.printingRequired && <Div className="subForm">
                                    <p><strong>{t('pleaseDeliver.label')}</strong></p>
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="printingInfo.name" required={true} taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="printingInfo.address.street" required={true} taxReturn={taxReturn} />
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <UniField name="printingInfo.address.number" taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="printingInfo.address.city" required={true} taxReturn={taxReturn} />
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <UniField name="printingInfo.address.zipCode" taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                </Div>}
                            </Div>

                            <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name="onMyBehalfRequired"
                                        validateForm={validateForm}
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                                {values.onMyBehalfRequired && <Div className="subForm">
                                    <p>{t('download.poa.placeholder')}</p>
                                    <StyledButton className="btn-primary download" disabled={this.state.poaDownloading} title={t('download.poa.label')} onClick={this.downloadPoa}>
                                        {this.state.poaDownloading && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                                        {!this.state.poaDownloading && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                                        {' '}{t('download.poa.label')}
                                    </StyledButton>

                                    {taxReturn.sections.married && taxReturn.sections.commonHousehold && taxReturn.sections.partners && <Div>
                                        <p>{t('download.non_working_spouse_declaration.placeholder')}</p>
                                        <StyledButton className="btn-primary download" disabled={this.state.nonWorkingSpouseDeclarationDownloading} title={t('download.non_working_spouse_declaration.label')} onClick={this.downloadNonWorkingSpouseDeclaration}>
                                            {this.state.nonWorkingSpouseDeclarationDownloading && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                                            {!this.state.nonWorkingSpouseDeclarationDownloading && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                                            {' '}{t('download.non_working_spouse_declaration.label')}
                                        </StyledButton>
                                    </Div>}

                                    {taxReturn.sections.children && taxReturn.sections.secondParentClaim && taxReturn.deductibles.children.length > 0 && <Div>
                                        <p>{t('download.second_parent_declaration.placeholder')}</p>
                                        <StyledButton className="btn-primary download" disabled={this.state.secondParentDeclarationDownloading} title={t('download.second_parent_declaration.label')} onClick={this.downloadSecondParentDeclaration}>
                                            {this.state.secondParentDeclarationDownloading && <FontAwesomeIcon icon={faSpinner} spin style={{color: theme.colors.white}} />}
                                            {!this.state.secondParentDeclarationDownloading && <FontAwesomeIcon icon={faDownload} style={{color: theme.colors.white}} />}
                                            {' '}{t('download.second_parent_declaration.label')}
                                        </StyledButton>
                                    </Div>}

                                    {onMyBehalfAttachments && Object.keys(onMyBehalfAttachments).length > 0 && <Div>
                                        <p><strong>{t('download.onMyBehalfAttachments.title.label')}</strong></p>
                                        <p>{t('download.onMyBehalfAttachments.title.placeholder')}</p>
                                        <Attachments
                                            attachments={onMyBehalfAttachments}
                                            taxReturn={taxReturn}
                                            attachFile={attachFile}
                                            deleteAttachment={deleteAttachment}
                                            t={t}
                                        />
                                    </Div>}

                                    {/*{taxReturn.year === 2021 && <Div>*/}
                                    {/*    <p><strong>{t('download.xmlWarning.label')}</strong></p>*/}
                                    {/*    <p>{t('download.xmlWarning.placeholder')}</p>*/}
                                    {/*</Div>}*/}
                                </Div>}
                            </Div>
                        </Div>
                    </Div>}

                    <Row>
                        <Col xs="12" sm="6" lg="4">
                            <Button onClick={() => wizardService.backToDashboard()}>
                                <FontAwesomeIcon icon={faHome} />{' '}
                                {t('buttons.backToDashboard.label')}
                            </Button>
                        </Col>
                        <Col xs="12" sm="6" lg="4">
                            <Button onClick={() => wizardService.previousStep()}>
                                <FontAwesomeIcon icon={faArrowCircleLeft} />{' '}
                                {t('buttons.previous.label')}
                            </Button>
                        </Col>
                        <Col xs="12" sm="6" lg="4">
                            {this.props.taxReturn.state === 'created' && <Button className="btn-primary" onClick={() => wizardService.goPay(validateForm, setFieldTouched, t, this.goPay)}>
                                <FontAwesomeIcon icon={faWallet} />{' '}
                                {t('buttons.pay.label')}
                            </Button>}

                            {this.props.taxReturn.state === 'paid' && <Button className="btn-primary" onClick={this.goToDownload}>
                                <FontAwesomeIcon icon={faDownload} />{' '}
                                {t('buttons.download.label')}
                            </Button>}
                        </Col>
                    </Row>
                </Form>
            </Div>)}
        </Formik>)
	}

    async goPay(): Promise<void> {
        const payment = await this.props.pay(this.props.taxReturn.id, this.props.lang);
        if (payment.gatewayUrl) {
            const checkout = await paymentService.checkout(payment.gatewayUrl);
            console.log(checkout);
        }
        history.push('/download/' + this.props.taxReturn.id);
    }
}

const SummaryRow = glamorous(Row)({
    marginBottom: '12px',
});

const StyledButton = glamorous(Button)({
    marginBottom: '24px',
    fontSize: '16px !important',
});

export default translate('translations')(StepFiveForm);
