import { Field, FieldProps, getIn } from 'formik';
import { translate, WithNamespaces } from 'react-i18next';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import HelpIcon from "../../../../components/HelpIcon/HelpIcon";
import {Div} from 'glamorous';
import FormFieldError from "../../../../components/FormFieldError/FormFieldError";

interface YearsProps {
    name: string;
    textKey?: string;
    required?: boolean;
    value?: any;
    validateForm?: Function;
}

class YearsInner extends React.Component<WithNamespaces & FieldProps & YearsProps> {
    render () {
        const {
            field,
            name,
            textKey,
            value,
            validateForm,
            required,
            t,
            form: { errors, touched },
        } = this.props;

        const years = ['2024', '2023', '2022', '2021']

        const _textKey = textKey ? textKey : field.name;
        const hasHelp = t(_textKey + '.helpText', {defaultValue: ''}) !== ''
            || t(_textKey + '.helpImage', {defaultValue: ''});

        const onChange = (eventOrPath) => {
            field.onChange(eventOrPath);
            setTimeout(validateForm, 100);
        };
        return (<Field name={name} value={value}>
            {({ form }) => (<Div className={!form.isValidating && getIn(errors, field.name) && getIn(touched, field.name) ? 'error' : ''}>
                <FormGroup>
                    <Label>
                        {t(_textKey + '.label')}{required ? '*' : ''}{' '}
                        {hasHelp && <HelpIcon helpKey={_textKey} t={t}/>}
                    </Label>
                    <select {...field} onChange={onChange} className={field.value ? null : 'empty'}>
                        <option value="">{t(_textKey + '.placeholder')}</option>
                        {years.map((year, index) => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </FormGroup>
                <FormFieldError fieldName={field.name} textKey={_textKey} isValidating={form.isValidating} t={t}/>
            </Div>)}
        </Field>);
    }
}

class Years extends React.Component<WithNamespaces & YearsProps> {
    render () {
        const { name, ...rest } = this.props;
        return (
            <Field name={name} fieldName={name} {...rest} component={YearsInner} />
        );
    }
}

export default translate('translations')(Years) as any;
