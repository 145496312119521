import React from 'react';
import glamorous,{Div} from 'glamorous';
import {Col, Row, Container} from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import constants from '../../../../../utils/constants';
import CatalogLabel from '../../../../../components/CatalogComboField/CatalogLabel';

interface IAddressSummaryProps {
    address: any;
    type: string;
    taxReturnId: string;
    t: Function;
}

export default class AddressSummary extends React.Component<IAddressSummaryProps> {
	render() {
        const { address, type, taxReturnId, t } = this.props;

        return (<Div className="formSection summary">
            <h3 className="noCheckbox">{t(`sections.${type}.label`)}</h3>
            <Div className="subForm">
                <Row>
                    <Col sm="6">
                        {t('permanentAddress.street.label')}<br/>
                    </Col>
                    <Col sm="6">
                        <strong>{address.street}</strong>
                    </Col>
                </Row>
                {address.number && <Row>
                    <Col sm="6">
                        {t('permanentAddress.number.label')}<br/>
                    </Col>
                    <Col sm="6">
                        <strong>{address.number}</strong>
                    </Col>
                </Row>}
                <Row>
                    <Col sm="6">
                        {t('permanentAddress.city.label')}<br/>
                    </Col>
                    <Col sm="6">
                        <strong>{address.city}</strong>
                    </Col>
                </Row>
                {address.zipCode && <Row>
                    <Col sm="6">
                        {t('permanentAddress.zipCode.label')}<br/>
                    </Col>
                    <Col sm="6">
                        <strong>{address.zipCode}</strong>
                    </Col>
                </Row>}
                {address.country && <Row>
                    <Col sm="6">
                        {t('permanentAddress.country.label')}<br/>
                    </Col>
                    <Col sm="6">
                        <strong>{address.country}</strong>
                    </Col>
                </Row>}
                <Link to={constants.ROUTES.WIZARD.STEP[2] + '/' + taxReturnId}>{t('editThisSection.label')}</Link>
            </Div>
        </Div>);
	}
}
