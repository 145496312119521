import { Formik } from 'formik';
import glamorous, { Div } from 'glamorous';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import {Button, Col, Row} from 'reactstrap';
import * as yup from 'yup';
import { ITaxReturn } from "../../../Dashboard/components/TaxReturn/ITaxReturn";
import UniField from '../UniFields/UniField';
import Years from '../UniFields/Years';
import { IStepProps } from '../../interfaces/IStepProps';

export interface IStepZeroFormProps extends IStepProps {
    createEmpty(taxReturn: ITaxReturn): Function;
}

const year = 2024;

class StepZeroForm extends React.Component<WithNamespaces & IStepZeroFormProps> {

	constructor(props: any) {
		super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

	handleSubmit(values: any): void {
        const data = {
            year: values.year,
            firstName: values.personalData.firstName,
            lastName: values.personalData.lastName
        };
        this.props.createEmpty(data);
    }

	createValidationSchema() {
        return yup.object().shape({
            year: yup.number().min(2021).max(2024),
            personalData: yup.object().shape({
                firstName: yup.string().required('global.notBlank'),
                lastName: yup.string().required('global.notBlank')
            })
		});
    }

	render() {
        const { t, taxReturn } = this.props;
        let defaultValues = {
            year,
            personalData: {
                firstName: this.props.loggedUser.firstName,
                lastName: this.props.loggedUser.lastName,
            }
        };
        if (taxReturn.id) {
            defaultValues = taxReturn;
        }

		return (
		    <Div>
                <h1>{t('newTaxReturn.title.label')}</h1>
                <Formik
                    initialValues={defaultValues}
                    onSubmit={this.handleSubmit}
                    validationSchema={this.createValidationSchema()}
                >
                    {({handleSubmit}) => {
                        return (
                            <form
                                onSubmit={handleSubmit}
                            >
                                <Row>
                                    <Col xs="12" sm="6" lg="5">
                                        <Years name="year" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="6" lg="5">
                                        <UniField name="personalData.firstName" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="6" lg="5">
                                        <UniField name="personalData.lastName" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="6" lg="5">
                                        <Button
                                            type="submit"
                                            className="btn-primary"
                                        >
                                            {t('newTaxReturn.button.label')}
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        )
                    }}
                </Formik>
            </Div>
		)
	}
}

export default translate('translations')(StepZeroForm);
