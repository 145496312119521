import { Field, FieldProps, getIn } from 'formik';
import { FormGroup, Label } from 'reactstrap';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { catalogActions } from './catalogActions';
import { ICatalogItem } from './interfaces';
import { langService } from '../../services/langService';
import {Div} from 'glamorous';
import HelpIcon from "../HelpIcon/HelpIcon";
import FormFieldError from "../FormFieldError/FormFieldError";
import {ITaxReturn} from "../../modules/Dashboard/components/TaxReturn/ITaxReturn";

interface CatalogComboProps {
    loadCatalogItems(name: string, lang: string);
    name: string;
    textKey?: string;
    required?: boolean;
    otherOptionName?: string;
    otherOptionValue?: string;
    catalogs: ICatalogItem[];
    catalogName: string; // name of catalog
    lang: string;
    value?: any;
    validateForm?: Function;
    setFieldValue?: Function;
    taxReturn: ITaxReturn;
    sort?: boolean;
}

class CatalogComboFieldInner extends React.Component<WithNamespaces & FieldProps & CatalogComboProps> {

    render () {
        const {
            field,
            textKey,
            required,
            otherOptionName,
            otherOptionValue,
            catalogs,
            catalogName,
            t,
            value,
            setFieldValue,
            validateForm,
            taxReturn,
            sort,
            form: { errors, touched },
        } = this.props;
        const _textKey = textKey ? textKey : field.name;

        if (value && !field.value && value !== field.value) {
            setFieldValue(field.name, value);
        }

        const hasHelp = t(_textKey + '.helpText', {defaultValue: ''}) !== ''
            || t(_textKey + '.helpImage', {defaultValue: ''}) !== '';

        const onChange = (eventOrPath) => {
            field.onChange(eventOrPath);
            setTimeout(validateForm,100);
        };
        const items: ICatalogItem[] = catalogs && catalogs[catalogName] || [];
        if (sort) {
            items.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
        }

        return (<Field>
            {({ form }) => (<Div className={!form.isValidating && getIn(errors, field.name) && getIn(touched, field.name) ? 'error' : ''}>
                <FormGroup>
                    <Label>
                        {t(_textKey + '.label')}{required ? '*' : ''}{' '}
                        {hasHelp && <HelpIcon helpKey={_textKey} taxReturn={taxReturn} t={t} />}
                    </Label>
                    <select {...field} onChange={onChange} className={field.value ? null : 'empty'}>
                        <option value="">{t(_textKey +  '.placeholder')}</option>
                        {items.map((item, index) => (
                            <option key={index} value={item.code}>{item.label}</option>
                        ))}
                        {otherOptionName && <option value={otherOptionValue}>{t(otherOptionName +  '.label')}</option>}
                    </select>
                </FormGroup>
                <FormFieldError fieldName={field.name} textKey={_textKey} isValidating={form.isValidating} t={t}/>
            </Div>)}
        </Field>);
    }
}


class CatalogComboField extends React.Component<WithNamespaces & CatalogComboProps> {

    private langs = {};

    constructor(props, s) {
        super(props);
        this.state = {
            langs: {},
        };
    }

	componentWillMount() {
		this.props.loadCatalogItems(this.props.catalogName, langService.getSavedLang());
    }

    componentDidUpdate() {
        if (this.langs[this.props.catalogName] && this.langs[this.props.catalogName] !== this.props.lang) {
            this.props.loadCatalogItems(this.props.catalogName, this.props.lang);
        }
        this.langs[this.props.catalogName] = this.props.lang;
    }

    render () {
        const { name, ...rest } = this.props;
        return (
            <Field name={name} fieldName={name} {...rest} component={CatalogComboFieldInner} />
        );
    }
}

const mapStateToProps = (state: any, props) => {
	return {
        catalogs: state.entities.catalogs,
        lang: state.app.lang.lang
    };
};

const mapDispatchToProps = (dispatch: Function, props) => {
	return {
        loadCatalogItems: (id: string, lang: string) => {
			return dispatch(catalogActions.onLoad(id, lang));
		}
    };
};

export default translate('translations')(connect(
	mapStateToProps,
	mapDispatchToProps
)(CatalogComboField)) as any;
