import {faHome, faArrowCircleLeft, faArrowCircleRight, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import {Div} from 'glamorous';
import { get } from 'lodash';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import {Button, Col, Row} from 'reactstrap';
import HelpIcon from '../../../../components/HelpIcon/HelpIcon';
import { IStepProps } from '../../interfaces/IStepProps';
import UniCheckbox from '../UniFields/UniCheckbox';
import UniField from '../UniFields/UniField';
import { WizardService } from '../../services/WizardService';
import CatalogComboField from "../../../../components/CatalogComboField/CatalogComboField";
import RegionalOfficesComboField from "../../../../components/CatalogComboField/RegionalOfficesComboField";
import CountriesComboField from "../../../../components/CatalogComboField/CountriesComboField";
import TaxNumberField from './FormFields/TaxNumberField';
import ValidationWrapper from "../../../../components/ValidationWrapper/ValidationWrapper";
import constants from "../../../../utils/constants";
import theme from "../../../../styles/theme";
import MyDatePicker from '../../../../components/DatePicker/DatePicker';

class StepTwoForm extends React.Component<WithNamespaces & IStepProps> {

	render() {
        const { t, taxReturn, lang, update, validate, shouldValidate } = this.props;

        if (!taxReturn.year) {
            return null;
        }

        const wizardService = new WizardService(2, taxReturn.id, update, validate, t);

        return (
          <Formik
            initialValues={taxReturn}
            onSubmit={values => console.log(values)}
            validateOnChange={false}
            validate={wizardService.validateAndSave}
          >
            {formik => {
              const {values, validateForm, setFieldTouched, setFieldValue} = formik;

              const datePickerProps = (path: string, required = false) => ({
                ...formik,
                name: path,
                value: get(values, path),
                lang,
                required,
                year: taxReturn.year,
                taxReturn: taxReturn
              });

              return (<Div>
                        <Div className="navButtons">
                            <Button title={t('buttons.backToDashboard.label')} onClick={() => wizardService.backToDashboard()}>
                                <FontAwesomeIcon icon={faHome} />
                            </Button>
                            <Button title={t('buttons.previous.label')} onClick={() => wizardService.previousStep()}>
                                <FontAwesomeIcon icon={faArrowCircleLeft} />
                            </Button>
                            <Button title={t('buttons.next.label')} className="btn-primary" onClick={() => wizardService.nextStep(validateForm, setFieldTouched, t)}>
                                <FontAwesomeIcon icon={faArrowCircleRight} />
                            </Button>
                        </Div>
                        <h1>{taxReturn.personalData.firstName} {taxReturn.personalData.lastName} {taxReturn.year}</h1>
                        <p>{t('wizard.subtitle.label')}</p>
                        <Form>
                            {shouldValidate && <ValidationWrapper wizardService={wizardService} validateForm={validateForm} setFieldTouched={setFieldTouched}/>}
                            <Div className="formSection">
                                <h3 className="noCheckbox">{t('sections.personalData.label')}</h3>
                                <p>{t('sections.personalData.placeholder')}</p>
                                <Div className="subForm">
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="personalData.firstName" required={true} taxReturn={taxReturn}
                                                  readOnly={taxReturn.state === constants.TAX_RETURN_STATE.PAID}
                                            />
                                            {taxReturn.state === constants.TAX_RETURN_STATE.PAID && <p>
                                                <FontAwesomeIcon icon={faExclamationTriangle} style={{color: theme.colors.red}} />{' '}
                                                {t('readOnly.firstName.label')}
                                            </p>}
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <UniField name="personalData.lastName" required={true} taxReturn={taxReturn}
                                                      readOnly={taxReturn.state === constants.TAX_RETURN_STATE.PAID}
                                            />
                                            {taxReturn.state === constants.TAX_RETURN_STATE.PAID && <p>
                                                <FontAwesomeIcon icon={faExclamationTriangle} style={{color: theme.colors.red}} />{' '}
                                                {t('readOnly.lastName.label')}
                                            </p>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="personalData.title" taxReturn={taxReturn} />
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <UniField name="personalData.birthLastName" taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="personalData.phone" taxReturn={taxReturn} />
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <UniField name="personalData.email" required={true} taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="personalData.nationality" required={true} taxReturn={taxReturn} />
                                        </Col>
                                        <Col xs="12" sm="6">
                                          <MyDatePicker {...datePickerProps('socialInsurance.birthDate', true)} />
                                        </Col>
                                    </Row>
                                </Div>
                            </Div>

                            <Div className="formSection">
                                <h3 className="noCheckbox">{t('sections.permanentAddress.label')} <HelpIcon helpKey="sections.permanentAddress" taxReturn={taxReturn} t={t} /></h3>
                                <p>{t('sections.permanentAddress.placeholder')}</p>
                                <Div className="subForm">
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="permanentAddress.street" required={true} taxReturn={taxReturn} />
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <UniField name="permanentAddress.number" taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="permanentAddress.city" required={true} taxReturn={taxReturn} />
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <UniField name="permanentAddress.zipCode" taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <CountriesComboField
                                                name="permanentAddress.country"
                                                required={true}
                                                taxReturn={taxReturn}
                                            />
                                        </Col>
                                    </Row>
                                </Div>
                            </Div>

                            <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name="sections.czechResidencyAddress"
                                        validateForm={validateForm}
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                                {values.sections.czechResidencyAddress && <Div className="subForm">
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="czechResidencyAddress.street" required={values.sections.czechResidencyAddress} taxReturn={taxReturn} />
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <UniField name="czechResidencyAddress.number" taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="czechResidencyAddress.city" required={values.sections.czechResidencyAddress} taxReturn={taxReturn} />
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <UniField name="czechResidencyAddress.zipCode" taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                </Div>}
                            </Div>

                            <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name="sections.lastDayAddress"
                                        validateForm={validateForm}
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                                {values.sections.lastDayAddress && <Div className="subForm">
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="lastDayAddress.street" required={values.sections.lastDayAddress} taxReturn={taxReturn} />
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <UniField name="lastDayAddress.number" taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="lastDayAddress.city" required={values.sections.lastDayAddress} taxReturn={taxReturn} />
                                        </Col>
                                        <Col xs="12" sm="6">
                                            <UniField name="lastDayAddress.zipCode" taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                </Div>}
                            </Div>

                            <Div className="formSection">
                                <Div className="subForm">
                                    <Row>
                                        <Col xs="12" sm="6" md="4">
                                            <CatalogComboField
                                                catalogName="tax-office"
                                                name="taxOffice"
                                                required={true}
                                                validateForm={validateForm}
                                                taxReturn={taxReturn}
                                            />
                                        </Col>
                                        <Col xs="12" sm="6" md="4">
                                            <TaxNumberField name="taxNumber" taxReturn={taxReturn}/>
                                        </Col>
                                        <Col xs="12" sm="6" md="4">
                                            <UniField name="birthNumber" taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="6" md="4">
                                            {values.taxOffice && <RegionalOfficesComboField
                                                catalogName="tax-office"
                                                name="regionalOffice"
                                                taxOfficeCode={values.taxOffice}
                                                taxReturn={taxReturn}
                                                required={true}
                                            />}
                                        </Col>
                                    </Row>
                                </Div>
                            </Div>

                            {taxReturn.year >= 2024 && <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name="socialInsurance.nextYearLumpPayer"
                                        validateForm={validateForm}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                            </Div>}

                            <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name="hasDatabox"
                                        validateForm={validateForm}
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                                {values.hasDatabox && <Div className="subForm">
                                    <Row>
                                        <Col xs="12" sm="6">
                                            <UniField name="databoxId" textKey="databoxId" required={false} taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                </Div>}
                            </Div>

                            <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name="submitViaDatabox"
                                        validateForm={validateForm}
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                            </Div>

                            {(!values.supplementaryStatement || !values.supplementaryStatement.selected) && <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name="correctiveTaxReturn"
                                        validateForm={validateForm}
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                            </Div>}

                            <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name={`supplementaryStatement.selected`}
                                        validateForm={validateForm}
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                                {values.supplementaryStatement && values.supplementaryStatement.selected && <Div className="subForm">
                                    <Row>
                                        <Col xs="12" sm="6" lg="3">
                                            <MyDatePicker
                                                {...datePickerProps(
                                                    'supplementaryStatement.date',
                                                    values.supplementaryStatement.selected
                                                )}
                                            />
                                        </Col>
                                        <Col xs="12" sm="6" lg="3">
                                            <UniField
                                                required={values.supplementaryStatement.selected}
                                                name="supplementaryStatement.originalTax"
                                                taxReturn={taxReturn}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="12" sm="12">
                                            <UniField
                                                required={values.supplementaryStatement.selected}
                                                name="supplementaryStatement.reason"
                                                taxReturn={taxReturn}
                                            />
                                        </Col>
                                    </Row>
                                </Div>}
                            </Div>

                            <Div className="formSection">
                                <h3>
                                    <UniCheckbox
                                        name="sections.nonCzTaxResident"
                                        validateForm={validateForm}
                                        placeholder={true}
                                        taxReturn={taxReturn}
                                    />
                                </h3>
                                {values.sections.nonCzTaxResident && <Div className="subForm">
                                    <Row>
                                        <Col xs="12" sm="6" md="4">
                                            <CountriesComboField
                                                name="nonCzTaxResident.country"
                                                required={values.sections.nonCzTaxResident}
                                                taxReturn={taxReturn}
                                            />
                                        </Col>
                                        <Col xs="12" sm="6" md="4">
                                            <UniField name="nonCzTaxResident.passportNumber" required={values.sections.nonCzTaxResident} taxReturn={taxReturn} />
                                        </Col>
                                        <Col xs="12" sm="6" md="4">
                                            <UniField name="nonCzTaxResident.worldWideIncome" required={values.sections.nonCzTaxResident} taxReturn={taxReturn} />
                                        </Col>
                                    </Row>
                                </Div>}
                            </Div>

                            <Row>
                                <Col xs="12" sm="6" lg="4">
                                    <Button onClick={() => wizardService.backToDashboard()}>
                                        <FontAwesomeIcon icon={faHome} />{' '}
                                        {t('buttons.backToDashboard.label')}
                                    </Button>
                                </Col>
                                <Col xs="12" sm="6" lg="4">
                                    <Button onClick={() => wizardService.previousStep()}>
                                        <FontAwesomeIcon icon={faArrowCircleLeft} />{' '}
                                        {t('buttons.previous.label')}
                                    </Button>
                                </Col>
                                <Col xs="12" sm="6" lg="4">
                                    <Button className="btn-primary" onClick={() => wizardService.nextStep(validateForm, setFieldTouched, t)}>
                                        {t('buttons.next.label')}{' '}
                                        <FontAwesomeIcon icon={faArrowCircleRight} />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Div>
                )}}
            </Formik>
		)
	}
}

export default translate('translations')(StepTwoForm);
