import {
    faPlusCircle,
    faTimesCircle,
    faArrowCircleRight,
    faHome,
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FieldArray, Form, Formik} from 'formik';
import glamorous, {P, Div, Span} from 'glamorous';
import { get } from 'lodash';
import React from 'react';
import { translate, WithNamespaces } from 'react-i18next';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import MyDatePicker from '../../../../components/DatePicker/DatePicker';
import HelpIcon from '../../../../components/HelpIcon/HelpIcon';
import { IStepProps } from '../../interfaces/IStepProps';
import NumberOfMonths from '../UniFields/NumberOfMonths';
import UniCheckbox from '../UniFields/UniCheckbox';
import UniField from '../UniFields/UniField';
import ExpensesType from './FormFields/ExpensesType';
import LeaseExpensesType from './FormFields/LeaseExpensesType';
import OtherIncomeType, { OTHER_INCOME_TYPES } from './FormFields/OtherIncomeType';
import theme from "../../../../styles/theme";
import { WizardService } from "../../services/WizardService";
import HelpLink from "../../../../components/HelpLink/HelpLink";
import CatalogComboField from "../../../../components/CatalogComboField/CatalogComboField";
import ValidationWrapper from "../../../../components/ValidationWrapper/ValidationWrapper";
import constants from "../../../../utils/constants";

interface StepOneState {
    otherDocumentsOpened: boolean;
    xmlWarningOpened: boolean;
}

class StepOneForm extends React.Component<WithNamespaces & IStepProps, StepOneState> {

    readonly state = {
        otherDocumentsOpened: false,
        xmlWarningOpened: false,
    };

    toggleOtherDocuments = () => {
        this.setState(prevState => ({
            otherDocumentsOpened: !prevState.otherDocumentsOpened,
        }));
    };

    toggleXmlWarning = () => {
        this.setState((prevState) => ({
            ...prevState,
            xmlWarningOpened: !prevState.xmlWarningOpened,
        }));
    };

	render() {
        const { t, taxReturn, update, validate, shouldValidate, lang, location } = this.props;

        if (!taxReturn.year) {
            return null;
        }

        const wizardService = new WizardService(1, taxReturn.id, update, validate, t);

		return (
            <Formik
                initialValues={taxReturn}
                onSubmit={values => console.log(values)}
                validateOnChange={false}
                validate={wizardService.validateAndSave}
            >
                {formik => {
                    const {values, validateForm, setFieldTouched, setFieldValue} = formik;

                    const datePickerProps = (path: string) => ({
                        ...formik,
                        name: path,
                        value: get(values, path),
                        lang: lang,
                        year: taxReturn.year,
                        minYear: 2018,
                        taxReturn: taxReturn
                    });

                    return (
                        <Div>
                            {/*{location.search === '?xml-warning' && <Modal isOpen={this.state.xmlWarningOpened} toggle={this.toggleXmlWarning} size="lg">*/}
                            {/*    <ModalHeader>{t('download.xmlWarning.label')}</ModalHeader>*/}
                            {/*    <ModalBody>{t('download.xmlWarning.placeholder')}</ModalBody>*/}
                            {/*    <ModalFooter>*/}
                            {/*        <Button color="secondary" onClick={this.toggleXmlWarning}>{t('close.label')}</Button>*/}
                            {/*    </ModalFooter>*/}
                            {/*</Modal>}*/}
                            <Div className="navButtons">
                                <Button title={t('buttons.backToDashboard.label')} onClick={() => wizardService.backToDashboard()}>
                                    <FontAwesomeIcon icon={faHome} />
                                </Button>
                                <Button title={t('buttons.next.label')} className="btn-primary" onClick={() => wizardService.nextStep(validateForm, setFieldTouched, t)}>
                                    <FontAwesomeIcon icon={faArrowCircleRight} />
                                </Button>
                            </Div>
                            <h1>{taxReturn.personalData.firstName} {taxReturn.personalData.lastName} {taxReturn.year}</h1>
                            <p>{t('wizard.subtitle.label')}</p>
                            <Form>
                                {shouldValidate && <ValidationWrapper wizardService={wizardService} validateForm={validateForm} setFieldTouched={setFieldTouched}/>}
                                <Div className="formSection">
                                    <h3>
                                        <UniCheckbox
                                            name="sections.employments"
                                            validateForm={validateForm}
                                            placeholder={true}
                                            taxReturn={taxReturn}
                                        />
                                    </h3>
                                    {values.sections.employments && <Div className="subForm">
                                        <Before>
                                            <strong>{t('employments.before.title.label')}:</strong> {t('employments.before.title.placeholder')}{' '}
                                        </Before>
                                        <Before>
                                            <HelpLink helpKey="employments.before.certificate_of_taxable_income" taxReturn={taxReturn} t={t} />
                                        </Before>
                                        <FieldArray name="employments">
                                            {(arrayHelpers) => {
                                                if (values.employments.length === 0) {
                                                    arrayHelpers.push({ grossIncome: '', paidInsurance: '', paidAdvances: '', witholdingTax: ''  });
                                                }
                                                return <Div className="cleared">
                                                    {values.employments.map((employment, index) => (
                                                    <Div key={index}>
                                                      <Row>
                                                          {index > 0 && <RemoveLink
                                                              onClick={() => {
                                                              arrayHelpers.remove(index);
                                                          }}>
                                                              <FontAwesomeIcon icon={faTimesCircle} style={{color: theme.colors.blue}}/>
                                                          </RemoveLink>}
                                                          <Col xs="12" sm="6" lg="3">
                                                              <UniField name={`employments.${index}.grossIncome`} textKey="employments.grossIncome" required={values.sections.employments} taxReturn={taxReturn} />
                                                          </Col>
                                                          {taxReturn.year < 2021 && <Col xs="12" sm="6" lg="3">
                                                              <UniField name={`employments.${index}.paidInsurance`} textKey="employments.paidInsurance" required={values.sections.employments && taxReturn.year < 2021} taxReturn={taxReturn} />
                                                          </Col>}
                                                          <Col xs="12" sm="6" lg="3">
                                                              <UniField name={`employments.${index}.paidAdvances`} textKey="employments.paidAdvances"  taxReturn={taxReturn} />
                                                          </Col>
                                                          <Col xs="12" sm="6" lg="3">
                                                              <UniField name={`employments.${index}.witholdingTax`} textKey="employments.witholdingTax" taxReturn={taxReturn} />
                                                          </Col>
                                                      </Row>
                                                      <Row>
                                                        <Col xs="12" sm="6" lg="3">
                                                          <UniField name={`employments.${index}.taxBonus`} textKey="employments.taxBonus" taxReturn={taxReturn} />
                                                        </Col>
                                                      </Row>
                                                    </Div>
                                                    ))}
                                                    <SubLink
                                                        onClick={() => arrayHelpers.push({ grossIncome: '', paidInsurance: '', paidAdvances: '', witholdingTax: ''  })}
                                                    >
                                                        <FontAwesomeIcon icon={faPlusCircle} style={{color: theme.colors.blue}} />{' '}
                                                        {t('employments.add.label')}
                                                    </SubLink>
                                                </Div>
                                            }}
                                        </FieldArray>
                                    </Div>}
                                </Div>

                                <Div className="formSection">
                                    <h3>
                                        <UniCheckbox
                                            name="sections.selfEmployment"
                                            validateForm={validateForm}
                                            placeholder={true}
                                            taxReturn={taxReturn}
                                        />
                                    </h3>
                                    {values.sections.selfEmployment && <Div className="subForm">
                                        <Before>
                                            <strong>{t('selfEmployment.before.title.label')}:</strong> {t('selfEmployment.before.title.placeholder')}{' '}
                                        </Before>
                                        <Before>
                                            <HelpLink helpKey="selfEmployment.before.trade_licence" taxReturn={taxReturn} t={t} />
                                            <HelpLink helpKey="selfEmployment.before.registration_from_the_financial_office" taxReturn={taxReturn} t={t} />
                                            <HelpLink helpKey="selfEmployment.before.registration_from_the_czech_social_security_office" taxReturn={taxReturn} t={t} />
                                            <HelpLink helpKey="selfEmployment.before.health_insurance_card_or_registration" taxReturn={taxReturn} t={t} />
                                            {!this.state.otherDocumentsOpened && <Div>
                                                <SubLink onClick={this.toggleOtherDocuments}>{t('selfEmployment.other.title.label')}</SubLink>
                                                <HelpIcon helpKey="selfEmployment.other.title" taxReturn={taxReturn} t={t} />
                                            </Div>}
                                            {this.state.otherDocumentsOpened && <Div>
                                                <HelpLink helpKey="selfEmployment.other.certificate_of_your_registration_number" taxReturn={taxReturn} t={t} />
                                                <HelpLink helpKey="selfEmployment.other.summary_of_payments_to_social_security_office" taxReturn={taxReturn} t={t} />
                                                <HelpLink helpKey="selfEmployment.other.summary_of_payments_to_health_insurance_office" taxReturn={taxReturn} t={t} />
                                            </Div>}
                                            <p>{t('selfEmployment.other.title.placeholder')} <a href="mailto:info@czechtaxesonline.cz">info@czechtaxesonline.cz</a></p>
                                        </Before>
                                        <Div className="cleared">
                                            <Row>
                                                <Col xs="12" sm="6" lg="4">
                                                    <UniField name="selfEmployment.tradeLicense.ico" taxReturn={taxReturn}
                                                        readOnly={taxReturn.state === constants.TAX_RETURN_STATE.PAID}
                                                    />
                                                    {taxReturn.state === constants.TAX_RETURN_STATE.PAID && <p>
                                                        <FontAwesomeIcon icon={faExclamationTriangle} style={{color: theme.colors.red}} />{' '}
                                                        {t('readOnly.ico.label')}
                                                    </p>}
                                                </Col>
                                                <Col xs="12" sm="6" lg="4">
                                                    <CatalogComboField
                                                        catalogName="type-of-activity"
                                                        name="selfEmployment.prevailingActivityName"
                                                        required={values.sections.selfEmployment}
                                                        validateForm={validateForm}
                                                        taxReturn={taxReturn}
                                                        sort={true}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="6" lg="4">
                                                    <UniField name="selfEmployment.income" required={values.sections.selfEmployment} taxReturn={taxReturn} />
                                                </Col>
                                                <Col xs="12" sm="6" lg="4">
                                                    <ExpensesType
                                                        name="selfEmployment.expensesType"
                                                        value={values.selfEmployment.expensesType}
                                                        required={values.sections.selfEmployment}
                                                        validateForm={validateForm}
                                                        setFieldValue={setFieldValue}
                                                        taxReturn={taxReturn}
                                                        main={true}
                                                    />

                                                </Col>
                                                {values.selfEmployment.expensesType === 'real' && <Col xs="12" sm="6" lg="4">
                                                    <UniField name="selfEmployment.expenses" required={values.sections.selfEmployment} taxReturn={taxReturn} />
                                                </Col>}
                                            </Row>

                                            <FieldArray name="selfEmployment.otherActivities">
                                                {(arrayHelpers) => {
                                                    return <Div className="cleared">
                                                        {values.selfEmployment.otherActivities && values.selfEmployment.otherActivities.map((activity, index) => (
                                                            <Row key={index}>
                                                                <RemoveLink
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                    }}>
                                                                    <FontAwesomeIcon icon={faTimesCircle} style={{color: theme.colors.blue}}/>
                                                                </RemoveLink>
                                                                <Col xs="12" sm="6" lg="3">
                                                                    <CatalogComboField
                                                                        catalogName="type-of-activity"
                                                                        name={`selfEmployment.otherActivities.${index}.activityName`}
                                                                        textKey="selfEmployment.otherActivities.activityName"
                                                                        required={values.sections.selfEmployment}
                                                                        validateForm={validateForm}
                                                                        taxReturn={taxReturn}
                                                                        sort={true}
                                                                    />
                                                                </Col>
                                                                <Col xs="12" sm="6" lg="3">
                                                                    <UniField name={`selfEmployment.otherActivities.${index}.income`} textKey="selfEmployment.otherActivities.income" required={values.sections.selfEmployment} taxReturn={taxReturn} />
                                                                </Col>
                                                                {values.selfEmployment.expensesType !== 'real' && <Col xs="12" sm="6" lg="3">
                                                                    <ExpensesType
                                                                        name={`selfEmployment.otherActivities.${index}.expensesType`}
                                                                        textKey="selfEmployment.otherActivities.expensesType"
                                                                        value={values.selfEmployment.expensesType ? (values.selfEmployment.expensesType === 'real' ? 'real' : values.selfEmployment.otherActivities[index].expensesType) : ''}
                                                                        required={values.sections.selfEmployment}
                                                                        validateForm={validateForm}
                                                                        setFieldValue={setFieldValue}
                                                                        taxReturn={taxReturn}
                                                                        allowRealExpenses={values.selfEmployment.expensesType === 'real'}
                                                                        allowLumpSum={values.selfEmployment.expensesType && values.selfEmployment.expensesType.substring(0, 4) === 'lump'}
                                                                        title={values.selfEmployment.expensesType ? undefined : t('selfEmployment.otherActivities.disabledExpensesType.label')}
                                                                        disabled={!values.selfEmployment.expensesType}
                                                                        main={false}
                                                                    />
                                                                </Col>}
                                                                {values.selfEmployment.expensesType === 'real' && <Col xs="12" sm="6" lg="3">
                                                                    <UniField name={`selfEmployment.otherActivities.${index}.expenses`} textKey="selfEmployment.otherActivities.expenses" required={values.sections.selfEmployment} taxReturn={taxReturn} />
                                                                </Col>}
                                                            </Row>
                                                        ))}
                                                        {values.selfEmployment.otherActivities.length < 3 && <Row className="add-activity">
                                                            <Col xs="12" sm="6" lg="4">
                                                                <SubLink
                                                                    onClick={() => arrayHelpers.push({ activityName: '', income: '', expensesType: '', expenses: ''})}
                                                                >
                                                                    <FontAwesomeIcon icon={faPlusCircle} style={{color: theme.colors.blue}} />{' '}
                                                                    {t('selfEmployment.otherActivities.add.label')}
                                                                </SubLink>
                                                            </Col>
                                                        </Row>}
                                                    </Div>
                                                }}
                                            </FieldArray>

                                            <h3>
                                                {t('selfEmployment.about.label')}{' '}
                                                <HelpIcon helpKey="selfEmployment.about" taxReturn={taxReturn} t={t} />
                                            </h3>

                                            <Row>
                                                <Col xs="12" sm="6" lg="3">
                                                    <MyDatePicker {...datePickerProps('selfEmployment.tradeLicense.commencementDate')} />
                                                </Col>
                                                <Col xs="12" sm="6" lg="3">
                                                    <MyDatePicker {...datePickerProps('selfEmployment.tradeLicense.interruptionDate')} />
                                                </Col>
                                                <Col xs="12" sm="6" lg="3">
                                                    <MyDatePicker {...datePickerProps('selfEmployment.tradeLicense.terminationDate')} />
                                                </Col>
                                                <Col xs="12" sm="6" lg="3">
                                                    <MyDatePicker {...datePickerProps('selfEmployment.tradeLicense.renewalDate')} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="12" sm="6" lg="3">
                                                    <NumberOfMonths
                                                        name="selfEmployment.tradeLicense.numberOfActivityMonths"
                                                        value={values.selfEmployment.tradeLicense.numberOfActivityMonths}
                                                        required={values.sections.selfEmployment}
                                                        validateForm={validateForm}
                                                        taxReturn={taxReturn}
                                                    />

                                                </Col>
                                            </Row>

                                            {values.selfEmployment.expensesType === 'real' && <React.Fragment>
                                            <SelfEmploymentStats>
                                                <Row>
                                                    <Col xs="12" sm="6" lg="3">
                                                        {t('selfEmployment.selfEmploymentStats.fixedAsset.label')}*{' '}
                                                        <HelpIcon helpKey="selfEmployment.selfEmploymentStats.fixedAsset" taxReturn={taxReturn} t={t} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.fixedAssetAtCommencement" required={values.selfEmployment.expensesType === 'real'} textKey="selfEmployment.selfEmploymentStats.atCommencement" errorByName={true} taxReturn={taxReturn} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.fixedAssetAtEnd" required={values.selfEmployment.expensesType === 'real'} textKey="selfEmployment.selfEmploymentStats.atEnd" errorByName={true} taxReturn={taxReturn} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" sm="6" lg="3">
                                                        {t('selfEmployment.selfEmploymentStats.moneyInCash.label')}
                                                        <HelpIcon helpKey="selfEmployment.selfEmploymentStats.moneyInCash" taxReturn={taxReturn} t={t} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.moneyInCashAtCommencement" textKey="selfEmployment.selfEmploymentStats.atCommencement" taxReturn={taxReturn} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.moneyInCashAtEnd" textKey="selfEmployment.selfEmploymentStats.atEnd" taxReturn={taxReturn} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" sm="6" lg="3">
                                                        {t('selfEmployment.selfEmploymentStats.moneyOnBankAccount.label')}
                                                        <HelpIcon helpKey="selfEmployment.selfEmploymentStats.moneyOnBankAccount" taxReturn={taxReturn} t={t} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.moneyOnBankAccountAtCommencement" textKey="selfEmployment.selfEmploymentStats.atCommencement" taxReturn={taxReturn} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.moneyOnBankAccountAtEnd" textKey="selfEmployment.selfEmploymentStats.atEnd" taxReturn={taxReturn} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" sm="6" lg="3">
                                                        {t('selfEmployment.selfEmploymentStats.stocks.label')}*
                                                        <HelpIcon helpKey="selfEmployment.selfEmploymentStats.stocks" taxReturn={taxReturn} t={t} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.stocksAtCommencement" required={values.selfEmployment.expensesType === 'real'} textKey="selfEmployment.selfEmploymentStats.atCommencement" errorByName={true} taxReturn={taxReturn} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.stocksAtEnd" required={values.selfEmployment.expensesType === 'real'} textKey="selfEmployment.selfEmploymentStats.atEnd" errorByName={true} taxReturn={taxReturn} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" sm="6" lg="3">
                                                        {t('selfEmployment.selfEmploymentStats.receivables.label')}*
                                                        <HelpIcon helpKey="selfEmployment.selfEmploymentStats.receivables" taxReturn={taxReturn} t={t} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.receivablesAtCommencement" required={values.selfEmployment.expensesType === 'real'} textKey="selfEmployment.selfEmploymentStats.atCommencement" errorByName={true} taxReturn={taxReturn} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.receivablesAtEnd" required={values.selfEmployment.expensesType === 'real'} textKey="selfEmployment.selfEmploymentStats.atEnd" errorByName={true} taxReturn={taxReturn} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" sm="6" lg="3">
                                                        {t('selfEmployment.selfEmploymentStats.otherItems.label')}
                                                        <HelpIcon helpKey="selfEmployment.selfEmploymentStats.otherItems" taxReturn={taxReturn} t={t} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.otherItemsAtCommencement" textKey="selfEmployment.selfEmploymentStats.atCommencement" taxReturn={taxReturn} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.otherItemsAtEnd" textKey="selfEmployment.selfEmploymentStats.atEnd" taxReturn={taxReturn} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" sm="6" lg="3">
                                                        {t('selfEmployment.selfEmploymentStats.payables.label')}*
                                                        <HelpIcon helpKey="selfEmployment.selfEmploymentStats.payables" taxReturn={taxReturn} t={t} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.payablesAtCommencement" required={values.selfEmployment.expensesType === 'real'} textKey="selfEmployment.selfEmploymentStats.atCommencement" errorByName={true} taxReturn={taxReturn} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.payablesAtEnd" required={values.selfEmployment.expensesType === 'real'} textKey="selfEmployment.selfEmploymentStats.atEnd" errorByName={true} taxReturn={taxReturn} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" sm="6" lg="3">
                                                        {t('selfEmployment.selfEmploymentStats.reserves.label')}*
                                                        <HelpIcon helpKey="selfEmployment.selfEmploymentStats.reserves" taxReturn={taxReturn} t={t} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.reservesAtCommencement" required={values.selfEmployment.expensesType === 'real'} textKey="selfEmployment.selfEmploymentStats.atCommencement" errorByName={true} taxReturn={taxReturn} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.reservesAtEnd" required={values.selfEmployment.expensesType === 'real'} textKey="selfEmployment.selfEmploymentStats.atEnd" errorByName={true} taxReturn={taxReturn} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" sm="6" lg="3">
                                                        {t('selfEmployment.selfEmploymentStats.wages.label')}*
                                                        <HelpIcon helpKey="selfEmployment.selfEmploymentStats.wages" taxReturn={taxReturn} t={t} />
                                                    </Col>
                                                    <Col xs="12" sm="6" lg="3">
                                                        <UniField name="selfEmployment.selfEmploymentStats.wages" required={values.selfEmployment.expensesType === 'real'} taxReturn={taxReturn} />
                                                    </Col>
                                                </Row>
                                            </SelfEmploymentStats>
                                            </React.Fragment>}
                                        </Div>
                                    </Div>}
                                </Div>

                                <Div className="formSection">
                                    <h3>
                                        <UniCheckbox
                                            name="sections.leaseIncome"
                                            validateForm={validateForm}
                                            placeholder={true}
                                            taxReturn={taxReturn}
                                        />
                                    </h3>
                                    {values.sections.leaseIncome && <Div className="subForm">
                                        <Row>
                                            <Col xs="12" sm="6" lg="4">
                                                <UniField name="leaseIncome.totalIncome" required={values.sections.leaseIncome} taxReturn={taxReturn} />
                                            </Col>
                                            <Col xs="12" sm="6" lg="4">
                                                <UniField name="leaseIncome.realEstateIncome" taxReturn={taxReturn} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12" sm="6" lg="4">
                                                <LeaseExpensesType t={t} name="leaseIncome.expensesType" value={values.leaseIncome.expensesType} required={values.sections.leaseIncome} />
                                            </Col>
                                            <Col xs="12" sm="6" lg="4">
                                                {values.leaseIncome.expensesType === 'real' && <UniField name="leaseIncome.expenses" required={values.sections.leaseIncome && values.leaseIncome.expensesType === 'real'} taxReturn={taxReturn} />}
                                            </Col>
                                        </Row>
                                    </Div>}
                                </Div>

                                <Div className="formSection">
                                    <h3>
                                        <UniCheckbox
                                            name="sections.capitalAssetsIncome"
                                            validateForm={validateForm}
                                            placeholder={true}
                                            taxReturn={taxReturn}
                                        />
                                    </h3>
                                    {values.sections.capitalAssetsIncome && <Div className="subForm">
                                        <Row>
                                            <Col xs="12" sm="6" lg="4">
                                                <UniField name="capitalAssetsIncome" required={values.sections.capitalAssetsIncome} taxReturn={taxReturn} />
                                            </Col>
                                        </Row>
                                    </Div>}
                                </Div>

                                {taxReturn.year > 2018 && <Div className="formSection">
                                  <h3>
                                    <UniCheckbox
                                      name="sections.abroadIncomes"
                                      validateForm={validateForm}
                                      placeholder={true}
                                      taxReturn={taxReturn}
                                    />
                                  </h3>
                                  {values.sections.abroadIncomes && <Div className="subForm">
                                      <FieldArray name="abroadIncomes">
                                        {(arrayHelpers) => {
                                          if (values.abroadIncomes.length === 0) {
                                            arrayHelpers.push({ country: '', employer: '', income: '', taxCzk: '', taxLocal: ''});
                                          }
                                          return <Div className="cleared">
                                            {values.abroadIncomes.map((income, index) => (
                                              <AbroadIncome key={index}>
                                                {index > 0 && <RemoveLink
                                                    onClick={() => {
                                                      arrayHelpers.remove(index);
                                                    }}>
                                                    <FontAwesomeIcon icon={faTimesCircle} style={{color: theme.colors.blue}}/>
                                                </RemoveLink>}
                                                <Col xs="12" sm="4" lg="4">
                                                  <CatalogComboField
                                                    catalogName="country"
                                                    name={`abroadIncomes.${index}.country`}
                                                    textKey="abroadIncomes.country"
                                                    required={true}
                                                    validateForm={validateForm}
                                                    taxReturn={taxReturn}
                                                  />
                                                </Col>
                                                <Col xs="12" sm="8" lg="8">
                                                  <UniField name={`abroadIncomes.${index}.employer`} textKey="abroadIncomes.employer" required={values.sections.abroadIncomes} taxReturn={taxReturn} />
                                                </Col>
                                                <Col xs="12" sm="6" lg="4">
                                                  <UniField name={`abroadIncomes.${index}.income`} textKey="abroadIncomes.income" required={values.sections.abroadIncomes} taxReturn={taxReturn} />
                                                </Col>
                                                <Col xs="12" sm="6" lg="4">
                                                  <UniField name={`abroadIncomes.${index}.taxCzk`} textKey="abroadIncomes.taxCzk" required={values.sections.abroadIncomes} taxReturn={taxReturn} />
                                                </Col>
                                                <Col xs="12" sm="6" lg="4">
                                                  <UniField name={`abroadIncomes.${index}.taxLocal`} textKey="abroadIncomes.taxLocal" required={values.sections.abroadIncomes} taxReturn={taxReturn} />
                                                </Col>
                                              </AbroadIncome>
                                            ))}
                                            <Row>
                                              <Col xs="12" sm="6" lg="4">
                                                <SubLink
                                                  onClick={() => arrayHelpers.push({ country: '', employer: '', income: '', taxCzk: '', taxLocal: ''})}
                                                >
                                                  <FontAwesomeIcon icon={faPlusCircle} style={{color: theme.colors.blue}} />{' '}
                                                  {t('abroadIncomes.add.label')}
                                                </SubLink>
                                              </Col>
                                            </Row>
                                          </Div>
                                        }}
                                      </FieldArray>
                                  </Div>}
                                </Div>}

                                <Div className="formSection">
                                    <h3>
                                        <UniCheckbox
                                            name="sections.otherIncomes"
                                            validateForm={validateForm}
                                            placeholder={true}
                                            taxReturn={taxReturn}
                                        />
                                    </h3>
                                    {values.sections.otherIncomes && <Div className="subForm">
                                        <FieldArray name="otherIncomes">
                                            {(arrayHelpers) => {
                                                if (values.otherIncomes.length === 0) {
                                                    arrayHelpers.push({ type: '', name: '', income: '', expenses: '', generatedAbroad: undefined});
                                                }
                                                return <Div className="cleared">
                                                    {values.otherIncomes.map((income, index) => (
                                                    <Row key={index}>
                                                        {index > 0 && <RemoveLink
                                                            onClick={() => {
                                                                arrayHelpers.remove(index);
                                                            }}>
                                                            <FontAwesomeIcon icon={faTimesCircle} style={{color: theme.colors.blue}}/>
                                                        </RemoveLink>}
                                                        <Col xs="12" sm="6" lg="4">
                                                            <OtherIncomeType
                                                                t={t}
                                                                name={`otherIncomes.${index}.type`}
                                                                textKey="otherIncomes.type"
                                                                value={values.otherIncomes[index].type}
                                                                required={values.sections.otherIncomes}
                                                            />
                                                            {values.otherIncomes[index].type === OTHER_INCOME_TYPES.OTHER &&
                                                                <UniField name={`otherIncomes.${index}.name`} textKey="otherIncomes.name" required={values.sections.otherIncomes && values.otherIncomes[index].type === OTHER_INCOME_TYPES.OTHER} taxReturn={taxReturn} />
                                                            }
                                                        </Col>
                                                        <Col xs="12" sm="6" lg="4">
                                                            <UniField name={`otherIncomes.${index}.income`} textKey="otherIncomes.income" required={values.sections.otherIncomes} taxReturn={taxReturn} />
                                                        </Col>
                                                        <Col xs="12" sm="6" lg="4">
                                                            <UniField name={`otherIncomes.${index}.expenses`} textKey="otherIncomes.expenses" required={values.sections.otherIncomes} taxReturn={taxReturn} />
                                                        </Col>
                                                        <Col xs="12" sm="6" lg="4">
                                                            <UniCheckbox
                                                                name={`otherIncomes.${index}.generatedAbroad`}
                                                                validateForm={validateForm}
                                                                textKey="otherIncomes.generatedAbroad"
                                                                taxReturn={taxReturn}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    ))}
                                                    <Row>
                                                        <Col xs="12" sm="6" lg="4">
                                                            <SubLink
                                                                onClick={() => arrayHelpers.push({ type: '', name: '', income: '', expenses: '', generatedAbroad: undefined})}
                                                            >
                                                                <FontAwesomeIcon icon={faPlusCircle} style={{color: theme.colors.blue}} />{' '}
                                                                {t('otherIncomes.add.label')}
                                                            </SubLink>
                                                        </Col>
                                                    </Row>
                                                </Div>
                                            }}
                                        </FieldArray>
                                    </Div>}
                                </Div>

                                <Row>
                                    <Col xs="12" sm="6" lg="4">
                                        <Button onClick={() => wizardService.backToDashboard()}>
                                            <FontAwesomeIcon icon={faHome} />{' '}
                                            {t('buttons.backToDashboard.label')}
                                        </Button>
                                    </Col>
                                    <Col xs="12" sm="6" lg="4"> </Col>
                                    <Col xs="12" sm="6" lg="4">
                                        <Button className="btn-primary" onClick={() => wizardService.nextStep(validateForm, setFieldTouched, t)}>
                                            {t('buttons.next.label')}{' '}
                                            <FontAwesomeIcon icon={faArrowCircleRight} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Div>
                    )
                }}
            </Formik>
		)
	}
}

const SubLink = glamorous(Span)({
    color: theme.colors.blue,
    cursor: 'pointer',
});

const RemoveLink = glamorous(Div)({
    position: 'absolute',
    marginLeft: '-8px',
    cursor: 'pointer',
});

const SelfEmploymentStats = glamorous(Div)({
    textAlign: 'right',
});

const Before = glamorous(Div)({
    float: 'left',
    marginRight: '12px',
    marginBottom: '14px',
});

const AbroadIncome = glamorous(Row)({
  marginBottom: '12px',
});

export default translate('translations')(StepOneForm);
